import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import CustomDataInputField from './CustomDataInputField';
import 'react-datepicker/dist/react-datepicker.css';
import './DateSelect.css';
import { OrderDateTimeInput } from '../../../__generated__/types';

export function DateSelect({
    clickDataTimeHandler
}: {
    clickDataTimeHandler: (time : OrderDateTimeInput | null) => void
}) {
    const [activeClass, setActiveClass] = useState('now')
    const [currentData, setCurrentData] = useState<Date | null>(null)

    // let data = new Date();
    // const handleCancel = () => {
    //     const data = { ...currentData };
    //     data.isOpen = false;
    //     setCurrentData(data);
    // }

    // const handleSelect = (time: any) => {
    //     setCurrentData({ time, isOpen: false });
    // }
    useEffect(() => {
        let obj = {date : '', time : ''}
        if (currentData) {
            obj.date = currentData.toLocaleDateString()
            obj.time = currentData.toLocaleTimeString()
            clickDataTimeHandler(obj)
        } else {
            clickDataTimeHandler(null)
        }
        
    }, [currentData])
    

    const filterPassedTime = (time: any) => {
        const currentDate = new Date()
        const selectedDate = new Date(time)

        return currentDate.getTime() < selectedDate.getTime()
    }

    return (
        <div className='options__option w-row'>
            <div className='column-halt-icon w-col w-col-2 w-col-small-2 w-col-tiny-2'>
                <img
                    src='https://uploads-ssl.webflow.com/61f36156ea53421f11e2920d/61f3c60b3bf93f2eb4bd7266_Group%20129.svg'
                    alt='time_image'
                    className='halt-icon-img'
                />
            </div>
            {/* <div className='option-text-column w-col w-col-2 w-col-small-2 w-col-tiny-2'>
                <div className='optiontext'>Время</div>
            </div> */}
            <div
                className='time__box'
            >
                <button
                className='time__now'
                    onClick={(e: any) => {
                        e.preventDefault()
                        setActiveClass('now')
                        setCurrentData(null)
                    }}
                    style={{ background: '#FFFFFF' }}
                >
                    <div className={activeClass === 'now' ? 'active' : ''} onClick={()=> setCurrentData(null)}>
                        Сейчас
                    </div>
                </button>
                <div
                    onClick={(e: any) => {
                        e.preventDefault()
                        setActiveClass('tomorrow')
                    }}
                    style={{ background: '#FFFFFF', marginLeft: '10px' }}
                    className={`time__datepicker ${activeClass === 'tomorrow' ? 'active' : ''}`}
                >
                    <DatePicker
                        selected={currentData}
                        onChange={(date: any) => {
                            setCurrentData(date)
                        }}
                        showTimeSelect
                        locale={ru}
                        timeIntervals={10}
                        timeCaption='время'
                        dateFormat='d MMMM HH:mm'
                        timeFormat='HH:mm'
                        minDate={new Date()}
                        filterTime={filterPassedTime}
                        customInput={<CustomDataInputField />}
                    />
                </div>
            </div>
        </div>
    )
}
