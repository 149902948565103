import * as Types from '../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GetBasePricesQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetBasePricesQuery = {
    __typename: 'Query'
    basePrices: Array<{
        __typename: 'BasePrice'
        id: number
        code: string
        title: string
        price: string
        minDistance: number
        maxDistance: number
    }>
}

export type CreateBasePriceMutationVariables = Types.Exact<{
    input: Types.CreateBasePriceInput
}>

export type CreateBasePriceMutation = {
    __typename: 'Mutation'
    createBasePrice: {
        __typename: 'BasePrice'
        code: string
        minDistance: number
        maxDistance: number
        price: string
        title: string
    }
}

export type DeleteBasePriceMutationVariables = Types.Exact<{
    id: Types.Scalars['Int']
}>

export type DeleteBasePriceMutation = {
    __typename: 'Mutation'
    deleteBasePrice: boolean
}

export type UpdateBasePriceMutationVariables = Types.Exact<{
    input: Types.UpdateBasePriceInput
}>

export type UpdateBasePriceMutation = {
    __typename: 'Mutation'
    updateBasePrice: {
        __typename: 'BasePrice'
        code: string
        id: number
        price: string
        title: string
        minDistance: number
        maxDistance: number
    }
}

export const GetBasePricesQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getBasePricesQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'basePrices' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minDistance' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxDistance' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useGetBasePricesQuery__
 *
 * To run a query within a React component, call `useGetBasePricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasePricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasePricesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBasePricesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetBasePricesQuery,
        GetBasePricesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetBasePricesQuery, GetBasePricesQueryVariables>(
        GetBasePricesQueryDocument,
        options
    )
}
export function useGetBasePricesQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetBasePricesQuery,
        GetBasePricesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetBasePricesQuery, GetBasePricesQueryVariables>(
        GetBasePricesQueryDocument,
        options
    )
}
export type GetBasePricesQueryHookResult = ReturnType<
    typeof useGetBasePricesQuery
>
export type GetBasePricesQueryLazyQueryHookResult = ReturnType<
    typeof useGetBasePricesQueryLazyQuery
>
export type GetBasePricesQueryQueryResult = Apollo.QueryResult<
    GetBasePricesQuery,
    GetBasePricesQueryVariables
>
export const CreateBasePriceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createBasePrice' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'CreateBasePriceInput'
                            }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'createBasePrice' },
                        name: { kind: 'Name', value: 'createBasePrice' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minDistance' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxDistance' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type CreateBasePriceMutationFn = Apollo.MutationFunction<
    CreateBasePriceMutation,
    CreateBasePriceMutationVariables
>

/**
 * __useCreateBasePriceMutation__
 *
 * To run a mutation, you first call `useCreateBasePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBasePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBasePriceMutation, { data, loading, error }] = useCreateBasePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBasePriceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateBasePriceMutation,
        CreateBasePriceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateBasePriceMutation,
        CreateBasePriceMutationVariables
    >(CreateBasePriceDocument, options)
}
export type CreateBasePriceMutationHookResult = ReturnType<
    typeof useCreateBasePriceMutation
>
export type CreateBasePriceMutationResult =
    Apollo.MutationResult<CreateBasePriceMutation>
export type CreateBasePriceMutationOptions = Apollo.BaseMutationOptions<
    CreateBasePriceMutation,
    CreateBasePriceMutationVariables
>
export const DeleteBasePriceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteBasePrice' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'deleteBasePrice' },
                        name: { kind: 'Name', value: 'deleteBasePrice' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type DeleteBasePriceMutationFn = Apollo.MutationFunction<
    DeleteBasePriceMutation,
    DeleteBasePriceMutationVariables
>

/**
 * __useDeleteBasePriceMutation__
 *
 * To run a mutation, you first call `useDeleteBasePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBasePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBasePriceMutation, { data, loading, error }] = useDeleteBasePriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBasePriceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteBasePriceMutation,
        DeleteBasePriceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteBasePriceMutation,
        DeleteBasePriceMutationVariables
    >(DeleteBasePriceDocument, options)
}
export type DeleteBasePriceMutationHookResult = ReturnType<
    typeof useDeleteBasePriceMutation
>
export type DeleteBasePriceMutationResult =
    Apollo.MutationResult<DeleteBasePriceMutation>
export type DeleteBasePriceMutationOptions = Apollo.BaseMutationOptions<
    DeleteBasePriceMutation,
    DeleteBasePriceMutationVariables
>
export const UpdateBasePriceDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateBasePrice' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'UpdateBasePriceInput'
                            }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'updateBasePrice' },
                        name: { kind: 'Name', value: 'updateBasePrice' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minDistance' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxDistance' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type UpdateBasePriceMutationFn = Apollo.MutationFunction<
    UpdateBasePriceMutation,
    UpdateBasePriceMutationVariables
>

/**
 * __useUpdateBasePriceMutation__
 *
 * To run a mutation, you first call `useUpdateBasePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBasePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBasePriceMutation, { data, loading, error }] = useUpdateBasePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBasePriceMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateBasePriceMutation,
        UpdateBasePriceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateBasePriceMutation,
        UpdateBasePriceMutationVariables
    >(UpdateBasePriceDocument, options)
}
export type UpdateBasePriceMutationHookResult = ReturnType<
    typeof useUpdateBasePriceMutation
>
export type UpdateBasePriceMutationResult =
    Apollo.MutationResult<UpdateBasePriceMutation>
export type UpdateBasePriceMutationOptions = Apollo.BaseMutationOptions<
    UpdateBasePriceMutation,
    UpdateBasePriceMutationVariables
>
