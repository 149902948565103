import React, { FC } from 'react'
import {Autocomplete, TextField} from '@mui/material';
import {ParentProfileList, ParentProfile} from '../../../__generated__/types';
import {useGetParentProfilesQuery} from './__generated__/ParentProfile';

interface propsType {
    handleProfileSelect: (profile: ParentProfile) => void
}
export const ParentProfileSelect: FC<propsType> = ({ handleProfileSelect }) => {
    const [options, setOptions] = React.useState<readonly ParentProfile[]>([])

    const { loading } = useGetParentProfilesQuery({
        variables: {
            input: null!
        },
        onCompleted: (data: { parentProfiles: ParentProfileList }) => {
            setOptions([...options, ...data.parentProfiles.items])
        }
    })

    return (
        <>
            <Autocomplete
                id='parent-profile-input'
                // value={point}
                isOptionEqualToValue={(option:ParentProfile, value) => option.id === value.id}
                onChange={(_, value) => value && handleProfileSelect(value)}
                getOptionLabel={(option) => option.phoneNumber || ''}
                options={options}
                loading={loading}
                renderInput={(params) => <TextField {...params} label='Профиль родителя' />}
            />
        </>
    )
}
