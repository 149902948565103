import { GridCellParams } from '@mui/x-data-grid'
import {
    Box,
    Button,
    IconButton,
    TextField,
    Switch,
    Modal,
    Typography
} from '@mui/material'
import { CarType } from '../../../__generated__/types'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useUpdateDriverProfileMutation} from '../__generated__/DriverProfiles'

const useStyles = makeStyles({
    form: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    label: {
        width: '100%',
        margin: 10
    },
    h4: {
        textAlign: 'left',
        paddingLeft: '30px'
    }
})
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
}

export function DriverProfile({
    id,
    closeWindow
}: {
    id: GridCellParams
    closeWindow: () => void
}) {
    const classes = useStyles()
    const [active, setActive] = useState<boolean>(id.row.active)
    const [carColor, setCarColor] = useState<string | null>(id.row.carColor)
    const [carDescription, setCarDescription] = useState<string | null>(
        id.row.carDescription
    )
    const [carPlateNumber, setCarPlateNumber] = useState<string | null>(
        id.row.carPlateNumber
    )

    const [carTypeIds, setCarTypeIds] = useState<Array<number>>(null!)
    const [driverTypeId, setDriverTypeId] = useState<number>(
        id.row.driverType.id
    )

    const [firstName, setFirstName] = useState<string>(id.row.firstName)
    const [lastName, setLastName] = useState<string>(id.row.lastName)
    const [middleName, setMiddleName] = useState<string | null>(
        id.row.middleName
    )
    const [phoneNumber, setPhoneNumber] = useState<string>(id.row.phoneNumber)
    const [telegramAccount] = useState<string | null>(
        id.row.telegramAccount
    )
    const [modalWindow, setModalWindow] = useState<boolean>(false)
    const [updateDriverProfile] = useUpdateDriverProfileMutation()

    const handleUpdateDriverProfile = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const data = {
            id: id.row.id,
            active: active,
            carColor: carColor,
            carDescription: carDescription,
            carPlateNumber: carPlateNumber,
            carTypeIds: carTypeIds,
            driverTypeId: driverTypeId,
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            phoneNumber: phoneNumber,
            telegramAccount: telegramAccount
        }
        updateDriverProfile({ variables: { input: data } })
    }
    const handleAlert = () => {
        setModalWindow(!modalWindow)
    }

    if (modalWindow)
        return (
            <Modal
                open={modalWindow}
                onClose={handleAlert}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                    >
                        Введите carTypeIds
                    </Typography>
                </Box>
            </Modal>
        )

    return (
        <div>
            <Box>
                <form
                    className={classes.form}
                    onSubmit={
                        carTypeIds ? handleUpdateDriverProfile : handleAlert
                    }
                >
                    <Box
                        className='asdasdasd'
                        sx={{ display: 'flex', paddingLeft: '20px' }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            Active
                        </Box>
                        <Switch
                            checked={active}
                            onChange={() => setActive(!active)}
                        />
                    </Box>
                    <TextField
                        className={classes.label}
                        name='lastName'
                        type='text'
                        id='standard-basic'
                        label='Фамилия'
                        variant='outlined'
                        value={lastName}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setLastName(e.target.value)
                        }}
                    />
                    <TextField
                        name='firstName'
                        className={classes.label}
                        type='text'
                        id='standard-basic'
                        label='Имя'
                        variant='outlined'
                        value={firstName}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setFirstName(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='middleName'
                        type='text'
                        id='standard-basic'
                        label='Отчество'
                        variant='outlined'
                        value={middleName ? middleName : ''}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setMiddleName(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='phoneNumber'
                        type='text'
                        id='standard-basic'
                        label='phoneNumber'
                        variant='outlined'
                        value={phoneNumber}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setPhoneNumber(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='carColor'
                        type='text'
                        id='standard-basic'
                        label='carColor'
                        variant='outlined'
                        value={carColor ? carColor : ''}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setCarColor(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='carDescription'
                        type='text'
                        id='standard-basic'
                        label='carDescription'
                        variant='outlined'
                        value={carDescription ? carDescription : ''}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setCarDescription(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='carPlateNumber'
                        type='text'
                        id='standard-basic'
                        label='carPlateNumber'
                        variant='outlined'
                        value={carPlateNumber ? carPlateNumber : ''}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setCarPlateNumber(e.target.value)
                        }}
                    />
                    <Box>
                        <h4 className={classes.h4}>DriverType</h4>
                        {id.row.driverType.title ? (
                            <Box className={classes.h4}>
                                {id.row.driverType.title}
                            </Box>
                        ) : (
                            ''
                        )}
                        <TextField
                            className={classes.label}
                            name='driverTypeId'
                            type='number'
                            id='standard-basic'
                            label='driverTypeId'
                            variant='outlined'
                            value={driverTypeId ? driverTypeId : ''}
                            onChange={(
                                e: React.FocusEvent<HTMLInputElement>
                            ) => {
                                e.preventDefault()
                                setDriverTypeId(+e.target.value)
                            }}
                        />

                        <Box sx={{ textAlign: 'start' }}>
                            <h4 className={classes.h4}>CarTypes</h4>
                            {id.row.carTypes.map(
                                (el: CarType, index: number) => (
                                    <p
                                        className={classes.h4}
                                        key={`${index}carType`}
                                    >
                                        {el.title} id = {el.id}
                                    </p>
                                )
                            )}
                            <TextField
                                className={classes.label}
                                name='carTypeIds'
                                type='text'
                                id='standard-basic'
                                label='Вводите id через пробел'
                                variant='outlined'
                                onChange={(
                                    e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                    e.preventDefault()

                                    setCarTypeIds(
                                        e.target.value
                                            .split(' ')
                                            .map((el) => Number(el))
                                    )
                                }}
                            />
                        </Box>
                    </Box>

                    <Box>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            style={{ width: 250, height: 50, marginRight: 5 }}
                        >
                            Подтвердить
                        </Button>
                        <IconButton
                            aria-label='delete'
                            size='large'
                            onClick={closeWindow}
                        >
                            <CancelRoundedIcon fontSize='inherit' />
                        </IconButton>
                    </Box>
                </form>
            </Box>
        </div>
    )
}
