import React, { useState } from 'react';
import { Box, Button, IconButton, TextField } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { makeStyles } from '@mui/styles';
import { useUpdateBasePriceMutation, useDeleteBasePriceMutation} from '../__generated__/BasePrice';

const useStyles = makeStyles({
    form: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center'
    },
    label: {
        width: '100%',
        margin: 10
    }
});

export function PriceItemForm(props: any) {
    const classes = useStyles();
    const [updateBasePrice] = useUpdateBasePriceMutation();
    const [deleteBasePrice] = useDeleteBasePriceMutation();
    const [code, setCode] = useState<string>(props.item.code);
    const [title, setTitle] = useState<string>(props.item.title);
    const [price, setPrice] = useState<string>(props.item.price);
    const [minDistance, setMinDistance] = useState<number>(props.item.minDistance);
    const [maxDistance, setMaxDistance] = useState<number>(props.item.maxDistance);

    const handleUpdateBasePrice = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            id: props.item.id,
            code: code,
            title: title,
            price: parseInt(price),
            minDistance: minDistance,
            maxDistance: maxDistance
        };
        updateBasePrice({ variables: { input: data } });
    };

    return (
        <div>
            <Box>
                <form className={classes.form} onSubmit={handleUpdateBasePrice}>
                    <h3>Редактирование</h3>
                    <TextField
                        name='code'
                        className={classes.label}
                        type='text'
                        id='standard-basic'
                        label='code'
                        variant='outlined'
                        value={code}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault();
                            setCode(e.target.value);
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='title'
                        type='text'
                        id='standard-basic'
                        label='title'
                        variant='outlined'
                        value={title}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault();
                            setTitle(e.target.value);
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='price'
                        type='text'
                        id='standard-basic'
                        label='price'
                        variant='outlined'
                        value={price}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault();
                            setPrice(e.target.value);
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='minDistance'
                        type='number'
                        id='standard-basic'
                        label='minDistance'
                        variant='outlined'
                        value={minDistance}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault();
                            setMinDistance(+e.target.value);
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='maxDistance'
                        type='number'
                        id='standard-basic'
                        label='maxDistance'
                        variant='outlined'
                        value={maxDistance}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault();
                            setMaxDistance(+e.target.value);
                        }}
                    />
                    <Box>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            style={{ width: 250, height: 50, marginRight: 5 }}
                        >
                            Подтвердить
                        </Button>
                        <Button
                            variant='contained'
                            color='error'
                            style={{ width: 250, height: 50 }}
                            onClick={() =>
                                deleteBasePrice({
                                    variables: { id: props.item.id }
                                })
                            }
                        >
                            Удалить
                        </Button>
                        <IconButton
                            aria-label='delete'
                            size='large'
                            onClick={() => props.isOpenEditWindowState()}
                        >
                            <CancelRoundedIcon fontSize='inherit' />
                        </IconButton>
                    </Box>
                </form>
            </Box>
        </div>
    );
}
