import { useState } from 'react';
import PriceItem from './PriceItem';
import PriceItemForm from './PriceItemForm';
import CreateBasePrice from './CreateBasePrice';
import { Box, Button } from '@mui/material';
import { useGetBasePricesQuery} from './__generated__/BasePrice';
import { BasePrice as BasePriceModal } from '../../__generated__/types';

export function BasePrice() {
    const [isOpenEditWindow, setIsOpenEditWindow] = useState<boolean>(false);
    const [isOpenCreateWindow, setIsOpenCreateWindow] =
        useState<boolean>(false);
    const [item, setItem] = useState<BasePriceModal>({
        id: 0,
        code: '',
        price: '',
        title: '',
        minDistance: 0,
        maxDistance: 0
    });
    const { loading, error, data, refetch } = useGetBasePricesQuery()

    const isOpenCreateWindowState = () => {
        setIsOpenCreateWindow(false);
    };
    const isOpenEditWindowState = (item: BasePriceModal) => {
        isOpenEditWindow
            ? setIsOpenEditWindow(false)
            : setIsOpenEditWindow(true);
        setItem(item);
    };

    if (loading) return <div>...Loading</div>;

    if (error) return <div>Error! ${error.message}</div>;

    if (isOpenCreateWindow)
        return (
            <CreateBasePrice
                isOpenCreateWindowState={isOpenCreateWindowState}
            />
        );

    if (isOpenEditWindow)
        return (
            <PriceItemForm
                item={item}
                isOpenEditWindowState={isOpenEditWindowState}
            />
        );

    return (
        <div className='main'>
            {data ? (
                <Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {data?.basePrices.map((item: any, index: number) => (
                            <PriceItem
                                key={index}
                                item={item}
                                isOpenEditWindowState={isOpenEditWindowState}
                            />
                        ))}
                    </Box>

                    <Box
                        sx={{
                            marginTop: 10,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            style={{ width: 250, marginRight: 10 }}
                            variant='outlined'
                            onClick={() => setIsOpenCreateWindow(true)}
                        >
                            Create
                        </Button>
                        <Button
                            style={{ width: 250, marginRight:85 }}
                            variant='outlined'
                            onClick={() => refetch()}
                        >
                            Update Data
                        </Button>
                    </Box>
                </Box>
            ) : (
                <div>No data</div>
            )}
        </div>
    );
}
