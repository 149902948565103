import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import CurrencyRubleOutlinedIcon from '@mui/icons-material/CurrencyRubleOutlined';
import PeopleIcon from '@mui/icons-material/People'
import ReorderIcon from '@mui/icons-material/Reorder'
import { Link } from 'react-router-dom';
import {DirectionsOutlined} from '@mui/icons-material';
import DriveEtaIcon from '@mui/icons-material/DriveEta'

export const mainListItems = (
    <React.Fragment>
        <Link
            to='parent-profiles'
            style={{ textDecoration: 'none', color: '#1976d2' }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <FamilyRestroomIcon />
                </ListItemIcon>
                <ListItemText primary='Родители' />
            </ListItemButton>
        </Link>
        <Link
            to='driver-profiles'
            style={{ textDecoration: 'none', color: '#1976d2' }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <DriveEtaIcon/>
                </ListItemIcon>
                <ListItemText primary='Водители' />
            </ListItemButton>
        </Link>
        <Link to='orders' style={{ textDecoration: 'none', color: '#1976d2' }}>
            <ListItemButton>
                <ListItemIcon>
                    <ReorderIcon />
                </ListItemIcon>
                <ListItemText primary='Заказы' />
            </ListItemButton>
        </Link>
        <Link to='users' style={{ textDecoration: 'none', color: '#1976d2' }}>
            <ListItemButton>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary='Пользователи' />
            </ListItemButton>
        </Link>
        <Link
            to='calculator'
            style={{ textDecoration: 'none', color: '#1976d2' }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <DirectionsOutlined />
                </ListItemIcon>
                <ListItemText primary='Расчет маршрута' />
            </ListItemButton>
        </Link>
        <Link
            to='parent-order'
            style={{ textDecoration: 'none', color: '#1976d2' }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <DirectionsOutlined />
                </ListItemIcon>
                <ListItemText primary='Оформление заказа' />
            </ListItemButton>
        </Link>
    </React.Fragment>
)

export const secondaryListItems = (
    <React.Fragment>
        <ListSubheader component='div' inset>
            Справочники
        </ListSubheader>

        <Link
            to='driver-types'
            style={{ textDecoration: 'none', color: '#1976d2' }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <DirectionsCarFilledIcon />
                </ListItemIcon>
                <ListItemText primary='Типы Водителей' />
            </ListItemButton>
        </Link>
        <Link
            to='car-types'
            style={{ textDecoration: 'none', color: '#1976d2' }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <EqualizerRoundedIcon />
                </ListItemIcon>
                <ListItemText primary='Типы Машин' />
            </ListItemButton>
        </Link>
        <Link
            to='base-prices'
            style={{ textDecoration: 'none', color: '#1976d2' }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <CurrencyRubleOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary='Базовые цены' />
            </ListItemButton>
        </Link>
    </React.Fragment>
)
