import React from 'react';

interface LoginData {
    username: string,
    password: string
}
interface AuthContextType {
    state: any;
    signIn: (data: LoginData) => void;
    signOut: () => void;
    signUp: (callback: VoidFunction) => void;
    bootstrap: () => void;
    changeDashboardName: (data: string) => void;
}
export const AuthContext = React.createContext<AuthContextType>(null!);
