import * as Types from '../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GetDriverProfileQueryVariables = Types.Exact<{
    id: Types.Scalars['Int']
}>

export type GetDriverProfileQuery = {
    __typename: 'Query'
    driverProfile?:
        | {
              __typename: 'DriverProfile'
              active: boolean
              carColor?: string | null | undefined
              carDescription?: string | null | undefined
              carPlateNumber?: string | null | undefined
              firstName: string
              id: number
              lastName: string
              middleName?: string | null | undefined
              phoneNumber: string
              telegramAccount?: string | null | undefined
              carTypes: Array<{
                  __typename: 'CarType'
                  code: string
                  coefficient: number
                  id: number
                  price: string
                  sort: number
                  title: string
              }>
              driverType?:
                  | {
                        __typename: 'DriverType'
                        id: number
                        code: string
                        sort: number
                        title: string
                    }
                  | null
                  | undefined
          }
        | null
        | undefined
}

export type GetDriverProfilesQueryVariables = Types.Exact<{
    [key: string]: never
}>

export type GetDriverProfilesQuery = {
    __typename: 'Query'
    driverProfiles: {
        __typename: 'DriverProfileList'
        items: Array<{
            __typename: 'DriverProfile'
            active: boolean
            carColor?: string | null | undefined
            carDescription?: string | null | undefined
            carPlateNumber?: string | null | undefined
            firstName: string
            id: number
            lastName: string
            middleName?: string | null | undefined
            phoneNumber: string
            telegramAccount?: string | null | undefined
            carTypes: Array<{
                __typename: 'CarType'
                code: string
                coefficient: number
                id: number
                price: string
                sort: number
                title: string
            }>
            driverType?:
                | {
                      __typename: 'DriverType'
                      id: number
                      code: string
                      sort: number
                      title: string
                  }
                | null
                | undefined
        }>
        pagination: {
            __typename: 'Pagination'
            limit?: number | null | undefined
            offset?: number | null | undefined
            totalCount?: number | null | undefined
        }
    }
}

export type CreateDriverProfileMutationVariables = Types.Exact<{
    input: Types.CreateDriverProfileInput
}>

export type CreateDriverProfileMutation = {
    __typename: 'Mutation'
    createDriverProfile: {
        __typename: 'DriverProfile'
        active: boolean
        carColor?: string | null | undefined
        carDescription?: string | null | undefined
        carPlateNumber?: string | null | undefined
        firstName: string
        lastName: string
        middleName?: string | null | undefined
        phoneNumber: string
        telegramAccount?: string | null | undefined
        carTypes: Array<{
            __typename: 'CarType'
            id: number
            code: string
            coefficient: number
            price: string
            sort: number
            title: string
        }>
        driverType?:
            | {
                  __typename: 'DriverType'
                  code: string
                  id: number
                  sort: number
                  title: string
              }
            | null
            | undefined
    }
}

export type UpdateDriverProfileMutationVariables = Types.Exact<{
    input: Types.UpdateDriverProfileInput
}>

export type UpdateDriverProfileMutation = {
    __typename: 'Mutation'
    updateDriverProfile: {
        __typename: 'DriverProfile'
        active: boolean
        carColor?: string | null | undefined
        carDescription?: string | null | undefined
        carPlateNumber?: string | null | undefined
        firstName: string
        id: number
        lastName: string
        middleName?: string | null | undefined
        phoneNumber: string
        telegramAccount?: string | null | undefined
        carTypes: Array<{
            __typename: 'CarType'
            id: number
            code: string
            coefficient: number
            price: string
            sort: number
            title: string
        }>
        driverType?:
            | {
                  __typename: 'DriverType'
                  code: string
                  id: number
                  sort: number
                  title: string
              }
            | null
            | undefined
    }
}

export const GetDriverProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDriverProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'driverProfile' },
                        name: { kind: 'Name', value: 'driverProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'carColor' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'carDescription'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'carPlateNumber'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'carTypes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'code'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'coefficient'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'price'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sort'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'driverType' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'code'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sort'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phoneNumber' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'telegramAccount'
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useGetDriverProfileQuery__
 *
 * To run a query within a React component, call `useGetDriverProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDriverProfileQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetDriverProfileQuery,
        GetDriverProfileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetDriverProfileQuery,
        GetDriverProfileQueryVariables
    >(GetDriverProfileDocument, options)
}
export function useGetDriverProfileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDriverProfileQuery,
        GetDriverProfileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetDriverProfileQuery,
        GetDriverProfileQueryVariables
    >(GetDriverProfileDocument, options)
}
export type GetDriverProfileQueryHookResult = ReturnType<
    typeof useGetDriverProfileQuery
>
export type GetDriverProfileLazyQueryHookResult = ReturnType<
    typeof useGetDriverProfileLazyQuery
>
export type GetDriverProfileQueryResult = Apollo.QueryResult<
    GetDriverProfileQuery,
    GetDriverProfileQueryVariables
>
export const GetDriverProfilesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDriverProfiles' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'driverProfiles' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'active'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'carColor'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'carDescription'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'carPlateNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'carTypes'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'code'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'coefficient'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'price'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sort'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'driverType'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'code'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sort'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'firstName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lastName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'middleName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'phoneNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'telegramAccount'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pagination' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'limit'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'offset'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'totalCount'
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useGetDriverProfilesQuery__
 *
 * To run a query within a React component, call `useGetDriverProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverProfilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDriverProfilesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetDriverProfilesQuery,
        GetDriverProfilesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetDriverProfilesQuery,
        GetDriverProfilesQueryVariables
    >(GetDriverProfilesDocument, options)
}
export function useGetDriverProfilesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDriverProfilesQuery,
        GetDriverProfilesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetDriverProfilesQuery,
        GetDriverProfilesQueryVariables
    >(GetDriverProfilesDocument, options)
}
export type GetDriverProfilesQueryHookResult = ReturnType<
    typeof useGetDriverProfilesQuery
>
export type GetDriverProfilesLazyQueryHookResult = ReturnType<
    typeof useGetDriverProfilesLazyQuery
>
export type GetDriverProfilesQueryResult = Apollo.QueryResult<
    GetDriverProfilesQuery,
    GetDriverProfilesQueryVariables
>
export const CreateDriverProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createDriverProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'CreateDriverProfileInput'
                            }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'createDriverProfile' },
                        name: { kind: 'Name', value: 'createDriverProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'carColor' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'carDescription'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'carPlateNumber'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'carTypes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'code'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'coefficient'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'price'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sort'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'driverType' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'code'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sort'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phoneNumber' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'telegramAccount'
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type CreateDriverProfileMutationFn = Apollo.MutationFunction<
    CreateDriverProfileMutation,
    CreateDriverProfileMutationVariables
>

/**
 * __useCreateDriverProfileMutation__
 *
 * To run a mutation, you first call `useCreateDriverProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverProfileMutation, { data, loading, error }] = useCreateDriverProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateDriverProfileMutation,
        CreateDriverProfileMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateDriverProfileMutation,
        CreateDriverProfileMutationVariables
    >(CreateDriverProfileDocument, options)
}
export type CreateDriverProfileMutationHookResult = ReturnType<
    typeof useCreateDriverProfileMutation
>
export type CreateDriverProfileMutationResult =
    Apollo.MutationResult<CreateDriverProfileMutation>
export type CreateDriverProfileMutationOptions = Apollo.BaseMutationOptions<
    CreateDriverProfileMutation,
    CreateDriverProfileMutationVariables
>
export const UpdateDriverProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateDriverProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'UpdateDriverProfileInput'
                            }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'updateDriverProfile' },
                        name: { kind: 'Name', value: 'updateDriverProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'active' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'carColor' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'carDescription'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'carPlateNumber'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'carTypes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'code'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'coefficient'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'price'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sort'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'driverType' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'code'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sort'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phoneNumber' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'telegramAccount'
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type UpdateDriverProfileMutationFn = Apollo.MutationFunction<
    UpdateDriverProfileMutation,
    UpdateDriverProfileMutationVariables
>

/**
 * __useUpdateDriverProfileMutation__
 *
 * To run a mutation, you first call `useUpdateDriverProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverProfileMutation, { data, loading, error }] = useUpdateDriverProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateDriverProfileMutation,
        UpdateDriverProfileMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateDriverProfileMutation,
        UpdateDriverProfileMutationVariables
    >(UpdateDriverProfileDocument, options)
}
export type UpdateDriverProfileMutationHookResult = ReturnType<
    typeof useUpdateDriverProfileMutation
>
export type UpdateDriverProfileMutationResult =
    Apollo.MutationResult<UpdateDriverProfileMutation>
export type UpdateDriverProfileMutationOptions = Apollo.BaseMutationOptions<
    UpdateDriverProfileMutation,
    UpdateDriverProfileMutationVariables
>
