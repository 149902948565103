import React, {useEffect} from 'react';
import DriverTypes from '../components/DriverTypes'
import {AuthContext} from '../context/AuthContext';

export default function DriveTypes() {
    const { state, changeDashboardName } = React.useContext(AuthContext);

    useEffect(() => {
        if (state.dashboardName !== 'DriverTypes') {
            changeDashboardName('DriverTypes');
        }
    }, []);
    return (

            <DriverTypes />
    );
}