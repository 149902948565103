import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { useEffect, useState } from 'react'
import { useCreateUserMutation} from '../__generated__/Users'

const useStyles = makeStyles({
    form: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    label: {
        width: '100%',
        margin: 10
    },
    h4: {
        textAlign: 'left',
        paddingLeft: '30px'
    }
})
export const CreateWindow = ({ closeWindow }: { closeWindow: () => void }) => {
    const classes = useStyles()
    const [login, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [roles, setRoles] = useState<Array<string>>([])
    const [isAdmin, setIsAdmin] = useState<boolean>(false)
    const [isDriver, setIsDriver] = useState<boolean>(false)
    const [isParent, setIsParent] = useState<boolean>(false)
    const [createUser] = useCreateUserMutation()

    const handleCreateUser = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const data = {
            login: login,
            password: password,
            roles: roles
        }
        createUser({ variables: { input: data } })
        closeWindow()
    }
    const handleOnChangeAdmin = () => {
        setIsAdmin(!isAdmin)
    }

    const handleOnChangeDriver = () => {
        setIsDriver(!isDriver)        
    }
    const handleOnChangeParent = () => {
        setIsParent(!isParent)        
    }

    useEffect(()=> {
        (isAdmin) ? setRoles([...roles, 'admin']) :  setRoles(roles.filter((item : string) => 'admin' !== item));
    },[isAdmin])
    useEffect(()=> {
        (isDriver) ? setRoles([...roles, 'driver']) :  setRoles(roles.filter((item : string) => 'driver' !== item));
    },[isDriver])
    useEffect(()=> {
        (isParent) ? setRoles([...roles, 'parent']) :  setRoles(roles.filter((item : string) => 'parent' !== item));
    },[isParent])
    
    
    return (
        <div>
            <Box>
                <h3 className={classes.h4}>Create Driver Profile</h3>
                <form className={classes.form} onSubmit={handleCreateUser}>
                    <TextField
                        name='login'
                        className={classes.label}
                        type='text'
                        id='standard-basic'
                        label='login'
                        variant='outlined'
                        value={login}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setLogin(e.target.value)
                        }}
                        required
                    />
                    <TextField
                        className={classes.label}
                        name='password'
                        type='text'
                        id='standard-basic'
                        label='password'
                        variant='outlined'
                        value={password}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setPassword(e.target.value)
                        }}
                        required
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={isAdmin} onChange={handleOnChangeAdmin} />}
                            label='Admin'
                        />
                        <FormControlLabel
                            control={<Checkbox checked={isDriver} onChange={handleOnChangeDriver} />}
                            label='Driver'
                        />
                        <FormControlLabel
                            control={<Checkbox checked={isParent} onChange={handleOnChangeParent} />}
                            label='Parent'
                        />
                    </FormGroup>

                    <Box>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            style={{ width: 250, height: 50, marginRight: 5 }}
                        >
                            Создать
                        </Button>
                        <IconButton
                            aria-label='delete'
                            size='large'
                            onClick={closeWindow}
                        >
                            <CancelRoundedIcon fontSize='inherit' />
                        </IconButton>
                    </Box>
                </form>
            </Box>
        </div>
    )
}
