import React  from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {ParentProfile} from '../../../__generated__/types';


interface ItemType {
    item: ParentProfile,
    handleOpenEditWindow: (event: any) => void;
    remove: (id: number) => void
}

export function Profile({ item, handleOpenEditWindow, remove } : ItemType) {

    return (
            <Card sx={{ maxWidth: 250, marginTop: 3, marginLeft: 3 }}>
                <CardContent sx={{ position: 'relative' }}>
                    <Box style={{ position: 'absolute', top: 16, right: 16, color: 'rgba(0, 0, 0, 0.5)' }}>
                        id: {item.id}
                    </Box>
                    <Typography variant='body2' color='text.primary'>
                        FirstName: {item.firstName}
                    </Typography>
                    <Typography variant='body2' color='text.primary'>
                        LastName: {item.lastName}
                    </Typography>
                    {
                        item.middleName
                            ? <Typography variant='body2' color='text.primary'>
                                MiddleName: {item.middleName}
                            </Typography>
                            : <div></div>
                    }
                    <Typography variant='body2' color='text.secondary'>
                        PhoneNumber: {item.phoneNumber}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                        TelegramAccount: {item.telegramAccount}
                    </Typography>
                    {
                        item.kids.length >= 1
                            ? <Box style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                                <Typography variant='body2' color='text.primary'>
                                    Kids:
                                </Typography>
                                {
                                    item.kids.map(( child, index ) => (
                                        <Box key={index} style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
                                            <Typography variant='body2' color='text.secondary'>
                                                name: {child.name}
                                            </Typography>
                                            <Typography style={{ marginLeft: '10px' }} variant='body2' color='text.secondary'>
                                                age: {child.age}
                                            </Typography>
                                        </Box>
                                    ))
                                }
                            </Box>
                            : <Box></Box>
                    }

                </CardContent>
                <CardActions style={{ marginBottom: 5, marginLeft: 5, marginRight: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        style={{ minHeight: '35px' }}
                        size='small'
                        variant='outlined'
                        startIcon={<EditIcon />}
                        onClick={() => handleOpenEditWindow(item)}
                    >
                        Изменить
                    </Button>
                    <Button
                        style={{ minHeight: '35px', minWidth: '25px' }}
                        size='small'
                        variant='contained'
                        startIcon={<DeleteIcon  style={{ marginLeft: 7}}/>}
                        onClick={() => remove(item.id)}
                    >
                    </Button>
                </CardActions>
            </Card>
    )
}
