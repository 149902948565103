import React from 'react';
import { makeStyles } from '@mui/styles';
import {KidProfile, ParentProfile} from '../../../__generated__/types';
import {TextField, Button, Box, IconButton} from '@mui/material';
import Kid from '../Kid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
    },
    label: {
        margin: 10,
        marginLeft: 20
    }
});

interface ItemType {
    item: ParentProfile,
    title: string,
    setCreateParentProfile: (prevState: ParentProfile) => void
    handleCreateProfileParent: (e: React.FormEvent<HTMLFormElement>) => void
}

export function ProfileForm ({ item, title, setCreateParentProfile, handleCreateProfileParent }: ItemType ) {
    const classes = useStyles();

    const handleChange = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.preventDefault();
        const { target: { name, value } } = event;
        setCreateParentProfile({ ...item, [name]: value});
    }
    const handleAddKid = () => {
        const kidsArray: KidProfile[] = [ ...item.kids ];
        kidsArray.push({id: item.kids.length + 1, age: 0, name: '' });
        setCreateParentProfile({ ...item, kids: [ ...kidsArray]});
    }
    const handleSubtractKid = (id: number) => {
        if (item.kids.length > 1) {
            const kidsArray: KidProfile[] = [ ...item.kids ];
            const findId = kidsArray.findIndex((prod: KidProfile) => prod.id === id);
            kidsArray.splice(findId, 1);
            setCreateParentProfile({ ...item, kids: [ ...kidsArray]});
        }
    }

    return (
        <form className={classes.form} onSubmit={handleCreateProfileParent}>
            <h3 style={{ marginTop: 20 }}>{title}</h3>
            <TextField
                name='firstName'
                className={classes.label}
                type='text'
                id='standard-basic'
                label='firstName'
                variant='standard'
                value={item.firstName}
                onChange={handleChange}
                required
            />
            <TextField
                name='lastName'
                className={classes.label}
                type='text'
                id='standard-basic'
                label='lastName'
                variant='standard'
                value={item.lastName}
                onChange={handleChange}
                required
            />
            <TextField
                name='middleName'
                className={classes.label}
                type='text'
                id='standard-basic'
                label='middleName'
                variant='standard'
                value={item.middleName}
                onChange={handleChange}
            />
            <TextField
                name='phoneNumber'
                className={classes.label}
                type='text'
                id='standard-basic'
                label='phoneNumber'
                variant='standard'
                value={item.phoneNumber}
                onChange={handleChange}
            />
            <TextField
                name='telegramAccount'
                className={classes.label}
                type='text'
                id='standard-basic'
                label='telegramAccount'
                variant='standard'
                value={item.telegramAccount}
                onChange={handleChange}
            />
            <Box style={{ margin: '10px 20px 40px 20px' }}>
                <Box  style={{ display: 'flex', flexDirection: 'row' }}>
                    <h4>Kids</h4>
                    <IconButton
                        color='primary'
                        onClick={handleAddKid}
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>
                </Box>

                <Box>
                    {
                             typeof (item.kids) !== 'undefined' && item.kids !== null && item.kids.map((child, index: number) => {
                                    return (
                                        <Box key={index}>
                                            <Kid
                                                id={+index}
                                                item={item}
                                                child={child}
                                                setCreateParentProfile={setCreateParentProfile}
                                                handleSubtractKid={handleSubtractKid}
                                            />
                                        </Box>
                                    )
                             })
                    }
                </Box>
            </Box>
            <Box>
                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    style={{ width: 150, height: 50 }}
                >
                    Сохранить
                </Button>
            </Box>
        </form>
    )
}
