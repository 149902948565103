import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react'
import styles from './ModalWrapper.module.css'
import { createPortal } from 'react-dom'
import CloseButtonSvg from '../../assets/images/close-button.svg'

const modalRootElement = document.querySelector('#modal')

interface propsType {
    children: PropsWithChildren<{}>
    open: boolean
    onClose: () => void
}

export const ModalWrapper: FC<propsType> = ({ children, open, onClose }) => {
    const element = useMemo(() => document.createElement('div'), [])

    useEffect(() => {
        modalRootElement?.appendChild(element)
        return () => {
            modalRootElement?.removeChild(element)
        }
    })

    if (open) {
        return createPortal(
            <div className={styles.background}>
                <div className={styles.modal}>
                    <div className={styles.content}>{children}</div>
                    <div className={styles.close} onClick={onClose}>
                        <img
                            src={CloseButtonSvg}
                            width='12.5px'
                            alt='close-button'
                        />
                    </div>
                </div>
            </div>,
            element
        )
    }
    return null
}
