import * as Types from '../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GetCarTypesQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetCarTypesQuery = {
    __typename: 'Query'
    carTypes: Array<{
        __typename: 'CarType'
        id: number
        code: string
        title: string
        price: string
        sort: number
        coefficient: number
    }>
}

export type CreateCarTypeMutationVariables = Types.Exact<{
    input: Types.CreateCarTypeInput
}>

export type CreateCarTypeMutation = {
    __typename: 'Mutation'
    createCarType: {
        __typename: 'CarType'
        code: string
        coefficient: number
        sort: number
        title: string
    }
}

export type DeleteCarTypeMutationVariables = Types.Exact<{
    id: Types.Scalars['Int']
}>

export type DeleteCarTypeMutation = {
    __typename: 'Mutation'
    deleteCarType: boolean
}

export type UpdateCarTypeMutationVariables = Types.Exact<{
    input: Types.UpdateCarTypeInput
}>

export type UpdateCarTypeMutation = {
    __typename: 'Mutation'
    updateCarType: {
        __typename: 'CarType'
        id: number
        code: string
        title: string
        price: string
        sort: number
        coefficient: number
    }
}

export const GetCarTypesQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCarTypesQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'carTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coefficient' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useGetCarTypesQuery__
 *
 * To run a query within a React component, call `useGetCarTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCarTypesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetCarTypesQuery,
        GetCarTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetCarTypesQuery, GetCarTypesQueryVariables>(
        GetCarTypesQueryDocument,
        options
    )
}
export function useGetCarTypesQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetCarTypesQuery,
        GetCarTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetCarTypesQuery, GetCarTypesQueryVariables>(
        GetCarTypesQueryDocument,
        options
    )
}
export type GetCarTypesQueryHookResult = ReturnType<typeof useGetCarTypesQuery>
export type GetCarTypesQueryLazyQueryHookResult = ReturnType<
    typeof useGetCarTypesQueryLazyQuery
>
export type GetCarTypesQueryQueryResult = Apollo.QueryResult<
    GetCarTypesQuery,
    GetCarTypesQueryVariables
>
export const CreateCarTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createCarType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CreateCarTypeInput' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'createCarType' },
                        name: { kind: 'Name', value: 'createCarType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coefficient' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type CreateCarTypeMutationFn = Apollo.MutationFunction<
    CreateCarTypeMutation,
    CreateCarTypeMutationVariables
>

/**
 * __useCreateCarTypeMutation__
 *
 * To run a mutation, you first call `useCreateCarTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarTypeMutation, { data, loading, error }] = useCreateCarTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCarTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateCarTypeMutation,
        CreateCarTypeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateCarTypeMutation,
        CreateCarTypeMutationVariables
    >(CreateCarTypeDocument, options)
}
export type CreateCarTypeMutationHookResult = ReturnType<
    typeof useCreateCarTypeMutation
>
export type CreateCarTypeMutationResult =
    Apollo.MutationResult<CreateCarTypeMutation>
export type CreateCarTypeMutationOptions = Apollo.BaseMutationOptions<
    CreateCarTypeMutation,
    CreateCarTypeMutationVariables
>
export const DeleteCarTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteCarType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'deleteCarType' },
                        name: { kind: 'Name', value: 'deleteCarType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type DeleteCarTypeMutationFn = Apollo.MutationFunction<
    DeleteCarTypeMutation,
    DeleteCarTypeMutationVariables
>

/**
 * __useDeleteCarTypeMutation__
 *
 * To run a mutation, you first call `useDeleteCarTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCarTypeMutation, { data, loading, error }] = useDeleteCarTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCarTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteCarTypeMutation,
        DeleteCarTypeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteCarTypeMutation,
        DeleteCarTypeMutationVariables
    >(DeleteCarTypeDocument, options)
}
export type DeleteCarTypeMutationHookResult = ReturnType<
    typeof useDeleteCarTypeMutation
>
export type DeleteCarTypeMutationResult =
    Apollo.MutationResult<DeleteCarTypeMutation>
export type DeleteCarTypeMutationOptions = Apollo.BaseMutationOptions<
    DeleteCarTypeMutation,
    DeleteCarTypeMutationVariables
>
export const UpdateCarTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateCarType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UpdateCarTypeInput' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'updateCarType' },
                        name: { kind: 'Name', value: 'updateCarType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coefficient' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type UpdateCarTypeMutationFn = Apollo.MutationFunction<
    UpdateCarTypeMutation,
    UpdateCarTypeMutationVariables
>

/**
 * __useUpdateCarTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCarTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarTypeMutation, { data, loading, error }] = useUpdateCarTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCarTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateCarTypeMutation,
        UpdateCarTypeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateCarTypeMutation,
        UpdateCarTypeMutationVariables
    >(UpdateCarTypeDocument, options)
}
export type UpdateCarTypeMutationHookResult = ReturnType<
    typeof useUpdateCarTypeMutation
>
export type UpdateCarTypeMutationResult =
    Apollo.MutationResult<UpdateCarTypeMutation>
export type UpdateCarTypeMutationOptions = Apollo.BaseMutationOptions<
    UpdateCarTypeMutation,
    UpdateCarTypeMutationVariables
>
