import React, { ChangeEvent, FC } from 'react'
import styles from './Comment.module.css'

interface propsType {
    handleChangeComment: (e: ChangeEvent<HTMLTextAreaElement>) => void
}
export const Comment: FC<propsType> = ({ handleChangeComment }) => {
    return (
        <div className={styles.wrapper}>
            <textarea
                className={styles.textarea}
                onChange={handleChangeComment}
                placeholder='Комментарий'
                maxLength={256}
            ></textarea>
        </div>
    )
}
