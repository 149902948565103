import React from 'react';
import {ApolloClient, ApolloLink, ApolloProvider, from, InMemoryCache, ServerError} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client'
import {AuthContext} from './context/AuthContext';
import RootRouter from './components/RootRouter';
import {onError} from '@apollo/client/link/error';


export default function App() {
    const apiUri = process.env.REACT_APP_API_URI
    const authUri = process.env.REACT_APP_AUTH_URI

    const [state, dispatch] = React.useReducer(
        (prevState: any, action: any) => {
            switch (action.type) {
                case 'RESTORE_TOKEN':
                    console.log('restore')
                    return {
                        ...prevState,
                        userToken: action.token,
                        isLoading: false,
                    };
                case 'SIGN_IN':
                    console.log('sign in')
                    return {
                        ...prevState,
                        isSignout: false,
                        userToken: action.token,
                    };
                case 'SIGN_OUT':
                    console.log('SIGN_OUT');
                    return {
                        ...prevState,
                        isSignout: true,
                        userToken: null,
                    };
                case 'CHANGE_DASHBOARD_NAME':
                    console.log('CHANGE_DASHBOARD_NAME');
                    return {
                        ...prevState,
                        dashboardName: action.data,
                    };
            }
        },
        {
            isLoading: true,
            isSignout: false,
            userToken: null,
            dashboardName: 'pageName',
        }
    );

    const authContext = React.useMemo(
        () => ({
            state,
            signIn: async (data: any) => {
                fetch(authUri + '/login', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username: data.username,
                        password: data.password
                    })
                })
                    .then((response) => response.json())
                    .then((json) => {
                        localStorage.setItem('userToken', json.token);
                        dispatch({type: 'SIGN_IN', token: json.token});
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            signOut: () => {
                console.log('signOut');
                localStorage.removeItem('userToken')
                return dispatch({type: 'SIGN_OUT'})
            },
            signUp: async (data: any) => {
                fetch(authUri + '/signup', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username: data.username,
                        email: data.email,
                        password: data.password
                    })
                })
                    .then((response) => response.json())
                    .then((json) => {
                        localStorage.setItem('userToken', json.token);
                        dispatch({type: 'SIGN_IN', token: json.token});
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            bootstrap: () => {
                let userToken;

                try {
                    userToken = localStorage.getItem('userToken');
                } catch (e) {
                    // Restoring token failed
                }
                dispatch({type: 'RESTORE_TOKEN', token: userToken});
            },
            changeDashboardName: (string: string) => {
                dispatch({type: 'CHANGE_DASHBOARD_NAME', data: string});
            },
        }),
        [state]
    );

    const uploadLink = createUploadLink({
        uri: apiUri
    })

    const authLink = new ApolloLink((operation, forward) => {
        const token = authContext.state.userToken;

        operation.setContext(({headers = {}}) => ({
            headers: {
                Authorization: token ? `Bearer ${token}` : null,
                ...headers
            }
        }))

        return forward(operation)
    })


    const errorLink = onError(({ graphQLErrors, networkError }) => {
        let sErr = networkError as ServerError;
        if (sErr && sErr.statusCode === 401) authContext.signOut();
    });

    const client = new ApolloClient({
        link: from([errorLink, authLink, uploadLink]),
        cache: new InMemoryCache()
    });

    return (
        <AuthContext.Provider value={authContext}>
            <ApolloProvider client={client}>
                <RootRouter />
            </ApolloProvider>
        </AuthContext.Provider>
    );
}
