import React, { useState } from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import {useCreateBasePriceMutation} from '../__generated__/BasePrice';



export function CreateBasePrice(props: any) {

    const [code, setCode] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [price, setPrice] = useState<string>('');
    const [minDistance, setMinDistance] = useState<number>(0);
    const [maxDistance, setMaxDistance] = useState<number>(0);

    const [createBasePrice] = useCreateBasePriceMutation()

    const handleCodeChange = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setCode(e.target.value);
    };
    const handleTitleChange = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setTitle(e.target.value);
    };
    const handlePriceChange = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setPrice(e.target.value);
    };
    const handleMinDistanceChange = (
        e: React.FocusEvent<HTMLInputElement>
    ): void => {
        e.preventDefault();
        setMinDistance(+e.target.value);
    };
    const handleMaxDistanceChange = (
        e: React.FocusEvent<HTMLInputElement>
    ): void => {
        e.preventDefault();
        setMaxDistance(+e.target.value);
    };
    const handleCreateBasePrice = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            code: code,
            title: title,
            price: parseInt(price),
            minDistance: minDistance,
            maxDistance: maxDistance
        };  
        createBasePrice({
            variables: {
                input: data
            }
        })

        props.isOpenCreateWindowState()
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 250,
                    marginLeft: 24
                }}
                onSubmit={
                    handleCreateBasePrice
                }
            >
                <h3>Создание</h3>
                <TextField
                    type='text'
                    id='standard-basic'
                    label='code'
                    variant='standard'
                    style={{ marginBottom: 10 }}
                    value={code}
                    onChange={handleCodeChange}
                />
                <TextField
                    type='text'
                    id='standard-basic'
                    label='title'
                    variant='standard'
                    style={{ marginBottom: 10 }}
                    value={title}
                    onChange={handleTitleChange}
                />
                <TextField
                    type='text'
                    id='standard-basic'
                    label='price'
                    variant='standard'
                    style={{ marginBottom: 10 }}
                    onChange={handlePriceChange}
                />
                <TextField
                    type='number'
                    id='standard-basic'
                    label='minDistance'
                    variant='standard'
                    style={{ marginBottom: 10 }}
                    onChange={handleMinDistanceChange}
                />
                <TextField
                    type='number'
                    id='standard-basic'
                    label='maxDistance'
                    variant='standard'
                    style={{ marginBottom: 10 }}
                    onChange={handleMaxDistanceChange}
                />
                <Box>
                    <input
                        style={{ width: 194, height: 50, marginTop: 10 }}
                        type='submit'
                        value='Создать'
                    />
                    <IconButton aria-label='delete' size='large' onClick={()=> props.isOpenCreateWindowState()} >
                        <CancelRoundedIcon fontSize='inherit' />
                    </IconButton>
                </Box>
            </form>
        </Box>
    );
}
