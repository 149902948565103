import * as Types from '../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type CalculateQueryVariables = Types.Exact<{
    input: Types.CalculateOrderInput
}>

export type CalculateQuery = {
    __typename: 'Query'
    calculate: { __typename: 'Order'; price: number }
}

export type GetDriverAndCarTypesQueryVariables = Types.Exact<{
    [key: string]: never
}>

export type GetDriverAndCarTypesQuery = {
    __typename: 'Query'
    driverTypes: Array<{
        __typename: 'DriverType'
        id: number
        title: string
        code: string
    }>
    carTypes: Array<{
        __typename: 'CarType'
        id: number
        code: string
        title: string
        price: string
        sort: number
        coefficient: number
    }>
}

export const CalculateDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'Calculate' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CalculateOrderInput' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'calculate' },
                        name: { kind: 'Name', value: 'calculate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useCalculateQuery__
 *
 * To run a query within a React component, call `useCalculateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateQuery(
    baseOptions: Apollo.QueryHookOptions<
        CalculateQuery,
        CalculateQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<CalculateQuery, CalculateQueryVariables>(
        CalculateDocument,
        options
    )
}
export function useCalculateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CalculateQuery,
        CalculateQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<CalculateQuery, CalculateQueryVariables>(
        CalculateDocument,
        options
    )
}
export type CalculateQueryHookResult = ReturnType<typeof useCalculateQuery>
export type CalculateLazyQueryHookResult = ReturnType<
    typeof useCalculateLazyQuery
>
export type CalculateQueryResult = Apollo.QueryResult<
    CalculateQuery,
    CalculateQueryVariables
>
export const GetDriverAndCarTypesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetDriverAndCarTypes' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'driverTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'carTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coefficient' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useGetDriverAndCarTypesQuery__
 *
 * To run a query within a React component, call `useGetDriverAndCarTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverAndCarTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverAndCarTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDriverAndCarTypesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetDriverAndCarTypesQuery,
        GetDriverAndCarTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetDriverAndCarTypesQuery,
        GetDriverAndCarTypesQueryVariables
    >(GetDriverAndCarTypesDocument, options)
}
export function useGetDriverAndCarTypesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDriverAndCarTypesQuery,
        GetDriverAndCarTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetDriverAndCarTypesQuery,
        GetDriverAndCarTypesQueryVariables
    >(GetDriverAndCarTypesDocument, options)
}
export type GetDriverAndCarTypesQueryHookResult = ReturnType<
    typeof useGetDriverAndCarTypesQuery
>
export type GetDriverAndCarTypesLazyQueryHookResult = ReturnType<
    typeof useGetDriverAndCarTypesLazyQuery
>
export type GetDriverAndCarTypesQueryResult = Apollo.QueryResult<
    GetDriverAndCarTypesQuery,
    GetDriverAndCarTypesQueryVariables
>
