import React, { useEffect } from 'react';
import BasePrice from '../components/BasePrice'
import { AuthContext } from '../context/AuthContext';

export default function BasePrices() {
  const { state, changeDashboardName } = React.useContext(AuthContext);

    useEffect(() => {
        if (state.dashboardName !== 'BasePrices') {
            changeDashboardName('BasePrices');
        }
    }, []);

  return (
      <BasePrice />
  )

  }
