import React, { useEffect, useState } from 'react'
import { UserList, Role } from '../../__generated__/types'
import { useGetUsersQuery} from './__generated__/Users'
import { CreateWindow } from './CreateWindow'
import { UpdateWindow } from './UpdateWindow'
import { Button, Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import UpdateIcon from '@mui/icons-material/Update'
import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridValueGetterParams
} from '@mui/x-data-grid'

const getRoles = (params: GridValueGetterParams) => {
    return params.row.roles.map((item: Role) => item.code)
}

const columns: GridColDef[] = [
    { field: 'id', headerName: 'id', width: 100 },
    { field: 'login', headerName: 'login', width: 200 },
    { field: 'roles', headerName: 'roles', width: 200, valueGetter: getRoles }
]

export const Users = () => {
    const [userList, setUserList] = React.useState<UserList>(null!)
    const [id, setId] = useState<GridCellParams>(null!)
    const [isOpenUpdateWindow, setIsOpenUpdateWindow] = useState<boolean>(false)
    const [isOpenCreateWindow, setIsOpenCreateWindow] = useState<boolean>(false)

    const { loading, data, refetch } = useGetUsersQuery()

    const handleOpenUpdateUserWindow = (params: GridCellParams) => {
        setId(params)
        setIsOpenUpdateWindow(true)
    }
    const closeWindow = () => {
        setIsOpenUpdateWindow(false)
        setIsOpenCreateWindow(false)
    }

    useEffect(() => {
        if (!loading && data) setUserList(data?.Users)
    }, [data, loading])

    if (isOpenUpdateWindow)
        return <UpdateWindow id={id} closeWindow={closeWindow} />
    if (isOpenCreateWindow) return <CreateWindow closeWindow={closeWindow} />
    if (loading) return <div>Loading</div>
    if (userList)
        return (
            <div style={{ height: 700, width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <IconButton
                        aria-label='delete'
                        size='large'
                        onClick={() => refetch()}
                    >
                        <UpdateIcon fontSize='inherit' />
                    </IconButton>
                </Box>
                <DataGrid
                    rows={userList.items}
                    columns={columns}
                    onCellDoubleClick={(params) =>
                        handleOpenUpdateUserWindow(params)
                    }
                />
                <Button onClick={() => setIsOpenCreateWindow(true)}>
                    Create User
                </Button>
            </div>
        )

    return <div>No users</div>
}
