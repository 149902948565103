import React, { FC } from 'react'
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult
} from 'react-beautiful-dnd'
import AddressInput from '../Address'
import { Point } from '../../../__generated__/types'

interface propsType {
    hide: boolean
    points: Point[]
    handleOnDragEnd: (result: DropResult) => void
}

export const FullRoutes: FC<propsType> = ({
    hide,
    points,
    handleOnDragEnd
}) => {
    return (
        <div
            className={
                hide
                    ? 'orderPanel__way orderPanel__way_hide'
                    : 'orderPanel__way'
            }
        >
            <div className='rout-level1 w-row'>
                <div
                    className='rout-level3  w-col-10 w-col-small-10 '
                    style={{ position: 'relative' }}
                >
                    <div
                        onClick={() => {}}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 19,
                            width: '20px',
                            height: '100%',
                            paddingBottom: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer'
                        }}
                    >
                        <svg
                            style={{
                                marginLeft: '3px',
                                marginTop: '7px'
                            }}
                            width='8'
                            height='8'
                            viewBox='0 0 8 8'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <circle cx='4' cy='4' r='4' fill='#48C0F3' />
                        </svg>
                        <div
                            style={{
                                width: '1px',
                                height: '100%',
                                backgroundColor: '#3E4958',
                                marginLeft: '5.80px',
                                marginTop: '4px',
                                marginBottom: '4px'
                            }}
                        ></div>
                        <svg
                            style={{
                                marginLeft: '2px',
                                marginBottom: '7px'
                            }}
                            width='10'
                            height='6'
                            viewBox='0 0 10 6'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M5.16 5.78667C5.08 5.89333 4.92 5.89333 4.84 5.78667L0.74 0.32C0.641115 0.188153 0.735191 0 0.9 0H9.1C9.26481 0 9.35889 0.188153 9.26 0.32L5.16 5.78667Z'
                                fill='#3E4958'
                            />
                        </svg>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            // minHeight: '168px',
                            marginLeft: '43px'
                        }}
                    >
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId='characters'>
                                {(provided: any) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {points.length >= 2 ? (
                                            points.map((item: any, index) => {
                                                return (
                                                    <Draggable
                                                        key={`${item}_${index}`}
                                                        draggableId={index.toString()}
                                                        index={index}
                                                    >
                                                        {(provided: any) => (
                                                            <div
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <AddressInput
                                                                    point={
                                                                        points[
                                                                            index
                                                                        ]
                                                                    }
                                                                    title={
                                                                        index ===
                                                                        0
                                                                            ? 'Откуда'
                                                                            : index ===
                                                                              points.length -
                                                                                  1
                                                                            ? 'Куда'
                                                                            : ''
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })
                                        ) : points.length === 1 ? (
                                            <>
                                                <Draggable
                                                    key={0}
                                                    draggableId={'0'}
                                                    index={0}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <AddressInput
                                                                point={
                                                                    points[0]
                                                                }
                                                                title={'Откуда'}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                                <Draggable
                                                    key={1}
                                                    draggableId={'1'}
                                                    index={1}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <AddressInput
                                                                point={null}
                                                                title={'Куда'}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            </>
                                        ) : (
                                            <>
                                                <AddressInput
                                                    point={null}
                                                    title={'Откуда'}
                                                />
                                                <AddressInput
                                                    point={null}
                                                    title={'Куда'}
                                                />
                                            </>
                                        )}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </div>
        </div>
    )
}
