import React  from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export function Item({ item, handleOpenEditWindow, removeDriveType } : any) {
    const imageSrc = '/images/driver-' + item.code + '.svg';

    return (
            <Card sx={{ maxWidth: 250, marginTop: 3, marginLeft: 3 }}>
                <CardMedia
                    component='img'
                    alt={item.title}
                    height='140'
                    image={imageSrc}
                />
                <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                        {item.title}
                    </Typography>
                </CardContent>
                <CardActions style={{ marginBottom: 5, marginLeft: 5, marginRight: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        style={{ minHeight: '35px' }}
                        size='small'
                        variant='outlined'
                        startIcon={<EditIcon />}
                        onClick={() => handleOpenEditWindow(item)}
                    >
                        Изменить
                    </Button>
                    <Button
                        style={{ minHeight: '35px', minWidth: '25px' }}
                        size='small'
                        variant='contained'
                        startIcon={<DeleteIcon  style={{ marginLeft: 7}}/>}
                        onClick={() => removeDriveType(item.id)}
                    >
                    </Button>
                </CardActions>
            </Card>
    )
}
