import React, { useEffect, useState } from 'react';
import CarType from './CarType';
import {
    CarType as CarTypeModal,
    UpdateCarTypeInput
} from '../../__generated__/types';
import {
    useGetCarTypesQuery, useCreateCarTypeMutation, useDeleteCarTypeMutation, useUpdateCarTypeMutation
} from './__generated__/CarTypes';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CarTypeForm from './CarTypeForm';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';

const useStyles = makeStyles({
    modal: {
        top: 0,
        left: '20%',
        width: '450px',
        height: '50%',
        marginTop: '190px',
        position: 'absolute',
        backgroundColor: '#EFEFEF',
        zIndex: '100',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0
    }
});

const testCarTypeArray: CarTypeModal[] = [
    {
        id: 1,
        title: 'TEST_Standart',
        code: 'test_standart',
        coefficient: 100,
        price: '1000',
        sort: 1
    },
    {
        id: 2,
        title: 'TEST_Business',
        code: 'test_susiness',
        coefficient: 200,
        price: '2000',
        sort: 2
    }
];

export function CarTypes() {
    const classes = useStyles();
    const [tabsCarTypes, setTabsCarTypes] = useState<CarTypeModal[]>([
        {
            id: 0,
            code: 'no_data',
            coefficient: 0,
            price: '100',
            title: 'no_data',
            sort: 0
        }
    ]);
    const [code, setCode] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [coeff, setCoeff] = useState<number>(0);
    const [sort, setSort] = useState<number>(0);
    const [isOpenEditWindow, setIsOpenEditWindow] = useState<boolean>(false);
    const [activeEditCarType, setActiveEditCarType] = useState<CarTypeModal>(
        testCarTypeArray[0]
    );
    const [isOpenCreateWindow, setIsOpenCreateWindow] =
        useState<boolean>(false);

    const [createCarType] = useCreateCarTypeMutation();
    const [deleteCarType] = useDeleteCarTypeMutation();
    const [updateCarType] = useUpdateCarTypeMutation();
    let carTypesArray;

    const { loading, data } = useGetCarTypesQuery({});

    const carTypes = data?.carTypes || testCarTypeArray;
    console.log(activeEditCarType);

    const handleOpenEditWindow = (item: CarTypeModal): void => {
        console.log(isOpenEditWindow);
        console.log(item);
        setIsOpenEditWindow(true);
        setActiveEditCarType(item);
    };

    const handleCodeChange = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setCode(e.target.value);
    };

    const handleTitleChange = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setTitle(e.target.value);
    };

    const handleCoeffChange = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setCoeff(+e.target.value);
    };

    const handleSortChange = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setSort(+e.target.value);
    };

    const handleCreateCarType = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            code: code,
            title: title,
            coefficient: coeff,
            sort: sort
        };
        createCarType({
            variables: {
                input: data
            }
        })
            .then((response) => {
                console.log(response);
                setIsOpenCreateWindow(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleUpdateCarType = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const newData: UpdateCarTypeInput = {
            code: activeEditCarType.code,
            title: activeEditCarType.title,
            sort: activeEditCarType.sort,
            coefficient: activeEditCarType.coefficient,
            id: 0
        };
        updateCarType({
            variables: {
                input: newData
            }
        })
            .then((response) => {
                console.log(response.data?.updateCarType);
                if (response.data) {
                    const tabs = [...tabsCarTypes];
                    const findId = tabs.findIndex(
                        (prod: any) => prod.code === activeEditCarType.code
                    );
                    if (findId) {
                        tabs[findId].code = activeEditCarType.code;
                        tabs[findId].title = activeEditCarType.title;
                        tabs[findId].sort = activeEditCarType.sort;
                        tabs[findId].coefficient =
                            activeEditCarType.coefficient;
                        tabs[findId].id = response.data.updateCarType.id;
                    }
                    setTabsCarTypes(tabs);
                    setIsOpenEditWindow(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsOpenEditWindow(false);
            });
    };

    const handleDeleteCarType = (id: number) => {
        return deleteCarType({
            variables: {
                id: id
            }
        })
            .then((response) => {
                if (response.data) {
                    console.log(response);
                    
                    if (tabsCarTypes.length > 1) {
                        const tabs = [...tabsCarTypes];
                        const findId = tabs.findIndex(
                            (prod: any) => prod.id === id
                        );
                        tabs.splice(findId, 1);
                        setTabsCarTypes(tabs);
                    } else {
                        setTabsCarTypes([
                            {
                                id: 1,
                                title: 'TEST_Standart',
                                code: 'test_standart',
                                coefficient: 100,
                                price: '1000',
                                sort: 1
                            }
                        ]);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (data !== undefined || !loading) {
            carTypesArray = data?.carTypes || testCarTypeArray;
            setTabsCarTypes(carTypesArray);
        } else {
            setTabsCarTypes(testCarTypeArray);
        }
    }, [data]);

    return (
        <div className='main'>
            {!loading ? (
                tabsCarTypes && tabsCarTypes.length >= 1 ? (
                    <Box>
                        {typeof carTypes !== 'undefined' &&
                            carTypes !== null &&
                            tabsCarTypes.map(
                                (item, index: number) =>
                                    typeof item !== 'undefined' &&
                                    item !== null && (
                                        <CarType
                                            key={index}
                                            item={item}
                                            handleOpenEditWindow={
                                                handleOpenEditWindow
                                            }
                                            remove={handleDeleteCarType}
                                        />
                                    )
                            )}

                        {isOpenEditWindow && activeEditCarType ? (
                            <Box className={classes.modal}>
                                <CarTypeForm
                                    item={activeEditCarType}
                                    handleUpdateCarType={handleUpdateCarType}
                                />
                                <Box className={classes.closeButton}>
                                    <IconButton
                                        aria-label='delete'
                                        size='large'
                                        onClick={() =>
                                            setIsOpenEditWindow(false)
                                        }
                                    >
                                        <CancelRoundedIcon fontSize='inherit' />
                                    </IconButton>
                                </Box>
                            </Box>
                        ) : (
                            <Box></Box>
                        )}
                    </Box>
                ) : (
                    <div>No data</div>
                )
            ) : (
                <div>...is loading</div>
            )}
            <br />
            {isOpenCreateWindow ? (
                <form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 250,
                        marginLeft: 24
                    }}
                    onSubmit={handleCreateCarType}
                >
                    <h4>Создание типа CarType</h4>
                    <TextField
                        type='text'
                        id='standard-basic'
                        label='Code'
                        variant='standard'
                        value={code}
                        onChange={handleCodeChange}
                        style={{ marginBottom: 10 }}
                    />
                    <TextField
                        type='number'
                        id='standard-basic'
                        label='coefficience'
                        variant='standard'
                        value={coeff}
                        onChange={handleCoeffChange}
                        style={{ marginBottom: 10 }}
                    />
                    <TextField
                        type='text'
                        id='standard-basic'
                        label='title'
                        variant='standard'
                        value={title}
                        onChange={handleTitleChange}
                        style={{ marginBottom: 10 }}
                    />
                    <TextField
                        type='number'
                        id='standard-basic'
                        label='sort'
                        variant='standard'
                        value={sort}
                        onChange={handleSortChange}
                        style={{ marginBottom: 10 }}
                    />
                    <Box>
                        <input
                            style={{ width: 194, height: 50, marginTop: 10 }}
                            type='submit'
                            value='Создать CarType'
                        />
                        <IconButton
                            aria-label='delete'
                            size='large'
                            onClick={() => setIsOpenCreateWindow(false)}
                        >
                            <CancelRoundedIcon fontSize='inherit' />
                        </IconButton>
                    </Box>
                </form>
            ) : (
                <Button
                    style={{
                        width: 194,
                        height: 50,
                        marginTop: 10,
                        marginLeft: 24
                    }}
                    variant='outlined'
                    onClick={() => setIsOpenCreateWindow(true)}
                >
                    Создать тип
                </Button>
            )}
        </div>
    );
}
