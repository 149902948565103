import React, { ChangeEvent, useEffect, useState } from 'react'
// import Options from './Options'
import DateSelect from './DateSelect'
import { MapContext } from '../../context/MapContext'
import {
    OrderFragment,
     useCalculateOrderQuery, useGetpaymentLinkLazyQuery,

     usePlaceOrderMutation
} from './__generated__/order'
import {
    CalculateOrderInput,
    // OrderAdditionalServiceInput,
    OrderDateTimeInput,
    PlaceOrderInput,
    CarType,
    ChairsInput, ParentProfile
} from '../../__generated__/types'
import ChairsSelect from './ChairsSelect'
import './OrderPanel.css'
import { AddAddress } from './AddAddress'
import { AuthContext } from '../../context/AuthContext'
import { FullRoutes } from './FullRoutes/FullRoutes'
import { ShortRoutes } from './ShortRoutes/ShortRoutes'
import { Comment } from './Comment/Comment'
import { OrderButton } from './OrderButton/OrderButton'
import { DropResult } from 'react-beautiful-dnd'
import { ModalWrapper } from '../ModalWrapper/ModalWrapper'
import { ModalPay } from './ModalPay/ModalPay'
import {Box, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {useGetDriverAndCarTypesQuery} from '../../pages/Calculator/__generated__/Calculator';
import {ParentProfileSelect} from './ParentProfileSelect/ParentProfileSelect';

export function OrderPanel() {
    const emptyOrder = {
        additionalServices: [],
        carType: 1,
        chairs: { boosters: 0, chairs: 0 },
        comment: null,
        dateTime: null,
        driverType: 1,
        points: [],
        promoCode: null,
        range: 0,
        parentProfileId: null!
    }
    const [order, updateOrder] = useState<CalculateOrderInput>(emptyOrder)
    const [placeOrder, setPlaceOrder] = useState<PlaceOrderInput>(emptyOrder)
    const [carTypes, updateCarTypes] = useState<CarType[]>([])
    const [price, updatePrice] = useState<number>(null!)
    const [parentProfile, updateParentProfile] = useState<ParentProfile>(null!)
    const [toggleActiveWay, setToggleActiveWay] = useState(true)
    const [pointsLength, setPointsLength] = useState(0)
    const [hide, setHide] = useState(false)
    const [isPaid, setIsPaid] = useState(false)
    const [openModalRoute, setOpenModalRoute] = useState(false)
    const [openPayModal, setOpenPayModal] = useState(false)

    const { points, distance, updateOrderPoints } = React.useContext(MapContext)
    const { state } = React.useContext(AuthContext)

    const [usePlaceOrder, { data }] = usePlaceOrderMutation()
    const [getPaymentLink, paymentLink] = useGetpaymentLinkLazyQuery()
    const { refetch } = useCalculateOrderQuery({
        fetchPolicy: 'standby',
        variables: {
            input: order
        },
        onCompleted: (data) => updateCalculatedFields(data.calculate)
    })
    const { data: types } = useGetDriverAndCarTypesQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'no-cache'
    });
    const driversTypes = types?.driverTypes;

    useEffect(() => {
        data?.placeOrder.id &&
            getPaymentLink({ variables: { orderId: data.placeOrder.id } })
    }, [data])
    useEffect(() => {
        if (paymentLink.data && !isPaid) {
            setOpenPayModal(true)
        }
    }, [paymentLink, isPaid])
    useEffect(() => {
        console.log('refetch')
        refetch({ input: { ...order, ...{ range: distance } } })
        setPlaceOrder((placeOrder) => ({
            ...placeOrder,
            ...{ range: distance }
        }))
    }, [distance, order])

    useEffect(() => {
        const newPoints = points.map((item) => {
            delete item.__typename
            return item
        })
        setPointsLength(points.length)
        setPlaceOrder((placeOrder) => ({
            ...placeOrder,
            ...{ points: newPoints }
        }))
    }, [points.length])

    const handleOpenModalRoute = () => setOpenModalRoute(true)
    const handleCloseModalRoute = () => setOpenModalRoute(false)
    const handleClosePayModal = () => setOpenPayModal(false)
    const handleNavigatePaymentLink = () => {
        paymentLink.data && window.open(paymentLink.data.paymentLink)
        setIsPaid(true)
    }
    console.log(parentProfile)

    const handleCarTypeChange = (event: any): void => {
        let carType = parseInt(event.target.value)
        updateOrder((order) => ({ ...order, ...{ carType: carType } }))
        setPlaceOrder((placeOrder) => ({
            ...placeOrder,
            ...{ carType: carType }
        }))
    };

    const handleDriveTypeChange = (event: any): void => {
        let driverType = parseInt(event.target.value)
        updateOrder((order) => ({ ...order, ...{ driverType: driverType } }))
        setPlaceOrder((placeOrder) => ({
            ...placeOrder,
            ...{ driverType: driverType }
        }))
    };

    const handlePlaceOrder = async () => {
        if (
            placeOrder.carType != 0 &&
            placeOrder.driverType != 0 &&
            points.length > 1 &&
            placeOrder.parentProfileId != null
        ) {
            const res = await usePlaceOrder({
                variables: { input: placeOrder }
            })
            console.log(res)
        } else handleOpenModalRoute()
    }

    const updateCalculatedFields = (order: OrderFragment) => {
        updateOrder((order) => ({ ...order, ...{ range: distance } }))
        updateCarTypes(order.carTypes)
        updatePrice(order.price)
    }

    const handleChangeComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const text = e.target.value
        updateOrder((order) => ({ ...order, ...{ comment: text } }))
        setPlaceOrder((placeOrder) => ({ ...placeOrder, ...{ comment: text } }))
    }

    const setParentProfile = (parentProfile: ParentProfile) => {
        console.log(parentProfile)
        updateParentProfile(parentProfile)
        updateOrder((order) => ({ ...order, ...{ parentProfileId: parentProfile.id } }))
        setPlaceOrder((placeOrder) => ({ ...placeOrder, ...{ parentProfileId: parentProfile.id } }))
    }

    const toggleClicDataTimeHandler = (time: OrderDateTimeInput | null) => {
        updateOrder((order) => ({ ...order, ...{ dateTime: time } }))
        setPlaceOrder((placeOrder) => ({
            ...placeOrder,
            ...{ dateTime: time }
        }))
    }
    const toggleUpdateChairsHandler = (chairs: ChairsInput) => {
        updateOrder((order) => ({ ...order, ...{ chairs: chairs } }))
        setPlaceOrder((placeOrder) => ({
            ...placeOrder,
            ...{ chairs: chairs }
        }))
    }
    // const toggleClickOptionsHandler = (
    //     options: Array<OrderAdditionalServiceInput>
    // ) => {
    //     updateOrder((order) => ({
    //         ...order,
    //         ...{ additionalServices: options }
    //     }))
    //     setPlaceOrder((placeOrder) => ({
    //         ...placeOrder,
    //         ...{ additionalServices: options }
    //     }))
    // }
    // const toggleClickPromocodeHandler = (promocode: string) => {
    //     updateOrder((order) => ({ ...order, ...{ promoCode: promocode } }))
    //     setPlaceOrder((placeOrder) => ({
    //         ...placeOrder,
    //         ...{ promoCode: promocode }
    //     }))
    // }

    const handleOnDragEnd = (result: DropResult) => {
        if (result.destination) {
            if (result.source.index !== result.destination.index) {
                const items = Array.from(points)
                const [reorderedPoint] = items.splice(result.source.index, 1)
                console.log(reorderedPoint)
                items.splice(result.destination.index, 0, reorderedPoint)
                updateOrderPoints(items)
            }
        }
    }
    const handleChangeToggleActiveWay = () => {
        setToggleActiveWay(!toggleActiveWay)
    }
    return (
        <>
            <div className={hide ? 'orderPanel orderPanel_hide' : 'orderPanel'}>
                <div
                    className={
                        hide
                            ? 'orderPanel__header orderPanel__header_hide'
                            : 'orderPanel__header'
                    }
                    onClick={() => setHide(!hide)}
                ></div>
                <div
                    className='orderPanel__swipebtn'
                    onClick={() => setHide(!hide)}
                ></div>

                {toggleActiveWay && pointsLength > 2 ? (
                    <ShortRoutes
                        hide={hide}
                        changeToggleActiveWay={handleChangeToggleActiveWay}
                        points={points}
                    />
                ) : (
                    <FullRoutes
                        hide={hide}
                        handleOnDragEnd={handleOnDragEnd}
                        points={points}
                    />
                )}
                <div
                    className={
                        hide
                            ? 'orderPanel__options orderPanel__options_hide'
                            : 'orderPanel__options'
                    }
                >
                    <ParentProfileSelect handleProfileSelect={setParentProfile}/>
                    <div className='options__option'>
                        <div className='option__adress'>
                            <div className='option__adress-left'>
                                <img
                                    src='https://uploads-ssl.webflow.com/61f36156ea53421f11e2920d/61f3c45a34a4592d4b3564a5_Group%20127.svg'
                                    alt=''
                                    className='halt-icon-img'
                                />
                            </div>
                            <div className='option__adress-right'>
                                <AddAddress point={points[points.length]} />
                            </div>
                        </div>
                    </div>
                    <DateSelect
                        clickDataTimeHandler={toggleClicDataTimeHandler}
                    />

                    <Box>
                        <FormControl sx={{ width: 300 }}>
                            <InputLabel id='driver-type-label'>DriveType</InputLabel>
                            <Select
                                labelId='driver-type-label'
                                id='driver-type'
                                value={order?.driverType}
                                label='DriveType'
                                onChange={handleDriveTypeChange}
                            >
                                {
                                    typeof (driversTypes) !== 'undefined' && driversTypes !== null &&
                                    driversTypes.map((item, index) => {
                                        return (
                                            <MenuItem key={index+1} value={item.id}>{item.title}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>

                        <FormControl sx={{ width: 300, marginTop: 2 }}>
                            <InputLabel id='car-type-label'>CarType</InputLabel>
                            <Select
                                labelId='car-type-label'
                                id='car-type'
                                value={order?.carType}
                                label='CarType'
                                onChange={handleCarTypeChange}
                            >
                                {
                                    typeof (carTypes) !== 'undefined' && carTypes !== null &&
                                    carTypes.map((item, index) => {
                                        return (
                                            <MenuItem key={index+1} value={item.id}>{item.title} {item.price} руб.</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <ChairsSelect
                        chairs={order?.chairs}
                        updateChairsHandler={toggleUpdateChairsHandler}
                    />
                    {/*<Options*/}
                    {/*    options={order?.additionalServices}*/}
                    {/*    profile={parentProfile}*/}
                    {/*    toggleClickOptionsHandler={toggleClickOptionsHandler}*/}
                    {/*    toggleClickPromocodeHandler={*/}
                    {/*        toggleClickPromocodeHandler*/}
                    {/*    }*/}
                    {/*    promocode={placeOrder.promoCode}*/}
                    {/*/>*/}
                    <Comment handleChangeComment={handleChangeComment} />
                </div>
                <OrderButton
                    placeOrder={placeOrder}
                    points={points}
                    state={state}
                    price={price}
                    handlePlaceOrder={handlePlaceOrder}
                />
            </div>

            {/* ==============Modals==================== */}

            <ModalWrapper open={openModalRoute} onClose={handleCloseModalRoute}>
                <h3>Выберите маршрут</h3>
            </ModalWrapper>
            <ModalPay
                open={openPayModal}
                handleClose={handleClosePayModal}
                handleNavigate={handleNavigatePaymentLink}
            />
        </>
    )
}
