import React, { useState, useEffect } from 'react';
import {
    CreateDriverTypeInput,
    UpdateDriverTypeInput,
    DriverType,
} from '../../__generated__/types';
import {
    useDriverTypesQuery,
    useDeleteDriverTypeMutation, useCreateDriverTypeMutation, useUpdateDriverTypeMutation
} from './__generated__/DriverTypes';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Item from './Item';
import IconButton from '@mui/material/IconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    modal: {
        top: 0,
        left: '20%',
        width: '450px',
        height: '50%',
        marginTop: '190px',
        position: 'absolute',
        backgroundColor: 'lightgrey',
        zIndex: '100',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    form: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        margin: 10
    }
});

export function DriverTypes() {
    const classes = useStyles();
    const [tabsFieldsDriverTypes, setTabsFieldsDriverTypes] = useState<DriverType[]>([{ id: 0, code: '',  title: '', sort: 0}]);
    const [isOpenEditWindow, setIsOpenEditWindow] = useState<boolean>(true);
    const [isOpenSaveWindow, setIsOpenSaveWindow] = useState<boolean>(false);
    const [activeDriveType, setActiveDriveType] = useState<DriverType>({ id: 0, code: '',  title: '', sort: 0});

    let driverTypes: any;
    console.log(tabsFieldsDriverTypes);
    console.log(driverTypes);
    const [ deleteDriverType ] = useDeleteDriverTypeMutation();
    const [ createDriverType ] = useCreateDriverTypeMutation();
    const [ updateDriverType ] = useUpdateDriverTypeMutation();

    const handleChange = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setActiveDriveType({ ...activeDriveType, [e.target.name]: e.target.name === 'sort' ? +e.target.value: e.target.value });
    }

    const handleOpenEditWindow = (item: DriverType): void => {
        setIsOpenEditWindow(true);
        console.log(activeDriveType);
        setActiveDriveType(item);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const newData: CreateDriverTypeInput = {
            code: activeDriveType.code,
            title: activeDriveType.title,
            sort: activeDriveType.sort
        }
        createDriverType({
                variables: {
                    input: newData
                }
        }).then((response) => {
            if (response.data) {
                const tabs = [ ...tabsFieldsDriverTypes ];
                tabs.push(response.data.createDriverType);
                setTabsFieldsDriverTypes(tabs);
            }
        })
    }
    const handleUpdateSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log('handleUpdateSubmit');
        const newData: UpdateDriverTypeInput = {
            code: activeDriveType.code,
            title: activeDriveType.title,
            sort: activeDriveType.sort
        }
        updateDriverType({
            variables: {
                input: newData
            }
        }).then((response) => {
            console.log(response.data?.updateDriverType)
            if (response.data) {
                console.log('update');
                const tabs = [ ...tabsFieldsDriverTypes ];
                const findId = tabs.findIndex((prod: any) => prod.sort === activeDriveType.sort);
                if (findId) {
                    tabs[findId].code = activeDriveType.code;
                    tabs[findId].title = activeDriveType.title;
                    tabs[findId].sort = activeDriveType.sort;
                    tabs[findId].id = response.data.updateDriverType.id;
                }
                setTabsFieldsDriverTypes(tabs);
                setIsOpenEditWindow(false);
            }
        })
    }
    const removeDriveType = (id: number) => {
        return deleteDriverType({
            variables: {
                id: id
            }
        })
            .then((response) => {
                if (response.data) {
                    if (tabsFieldsDriverTypes.length > 1) {
                        const tabs = [...tabsFieldsDriverTypes];
                        const findId = tabs.findIndex((prod: any) => prod.id === id);
                        tabs.splice(findId, 1);
                        setTabsFieldsDriverTypes(tabs);
                    } else {
                        setTabsFieldsDriverTypes([{ id: 0, code: '',  title: '', sort: 0}]);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    const {data, loading } = useDriverTypesQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'no-cache'
    });

    useEffect( () => {
        if (data !== undefined || !loading ) {
            console.log('useDriverTypesQuery')
            driverTypes = data?.driverTypes;
            setTabsFieldsDriverTypes(driverTypes);
            console.log(driverTypes);
        }
    },[data])

    return (
        <div className='main'>
            <Box style={{ display: 'flex', flexDirection: 'row'}}>

                {tabsFieldsDriverTypes
                    ?
                    typeof (tabsFieldsDriverTypes) !== 'undefined' && tabsFieldsDriverTypes !== null && tabsFieldsDriverTypes.map((item: any, index: number) =>
                        typeof (item) !== 'undefined' && item !== null &&
                        <Item
                            key={index}
                            item={item}
                            handleOpenEditWindow={handleOpenEditWindow}
                            removeDriveType={removeDriveType}
                        />
                    )
                    : <div>...database is empty</div>
                }
                { isOpenEditWindow
                    ?
                    < Box className={classes.modal}>
                        <form className={classes.form} onSubmit={handleUpdateSubmit}>
                            <label className={classes.label}>
                                code:
                                <input type='text' name='code' value={activeDriveType.code} onChange={handleChange} />
                            </label>
                            <label className={classes.label}>
                                title:
                                <input type='text' name='title' value={activeDriveType.title} onChange={handleChange} />
                            </label>
                            <label className={classes.label}>
                                sort:
                                <input type='number' name='sort' value={activeDriveType.sort} onChange={handleChange} />
                            </label>
                            <input style={{ width: 300, height: 50 }} type='submit' value='Сохранить' />
                        </form>
                        <Box className={classes.closeButton}>
                            <IconButton
                                aria-label='delete'
                                size='large'
                                onClick={() => setIsOpenEditWindow(false)}
                            >
                                <CancelRoundedIcon fontSize='inherit' />
                            </IconButton>
                        </Box>
                    </Box>
                    : <Box></Box>
                }

            </Box>
            <br/>
            <br/>

            {isOpenSaveWindow ?
                <form  onSubmit={handleSubmit}>
                    <h3>Создание нового типа</h3>
                    <Box>
                        <TextField
                            label='code'
                            name='code'
                            type='text'
                            id='outlined-size-small'
                            value={activeDriveType.code}
                            size='small'
                            onChange={handleChange}
                            style={{ marginRight: '10px' }}
                         />
                        <TextField
                            label='sort'
                            name='sort'
                            type='number'
                            id='outlined-size-small'
                            value={activeDriveType.sort}
                            size='small'
                            onChange={handleChange}
                            style={{ marginRight: '10px' }}
                        />
                        <TextField
                            label='title'
                            name='title'
                            type='text'
                            id='outlined-size-small'
                            value={activeDriveType.title}
                            size='small'
                            onChange={handleChange}
                        />
                    </Box>
                    <input style={{ width: 194, height: 50, marginTop: 10 }} type='submit' value='Создать тип'/>
                </form>
                : <Button
                    variant='outlined'
                    onClick={() => setIsOpenSaveWindow(true)}
                >
                    Создать тип
                </Button>
            }

        </div>
    );
}
