import React from 'react';
import { makeStyles } from '@mui/styles';
import {CarType} from '../../../__generated__/types';
import {TextField, Button} from '@mui/material';

const useStyles = makeStyles({
    form: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center'
    },
    label: {
        width: '100%',
        margin: 10
    }
});

interface ItemType {
    item: CarType,
    handleUpdateCarType: (e: React.FormEvent<HTMLFormElement>) => void
}
export function CarTypeForm ({ item, handleUpdateCarType }: ItemType ) {
    const [editCarType, setEditCarType] = React.useState<CarType>(item);
    const classes = useStyles();

    const handleChange = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setEditCarType({ ...item, [e.target.name]: e.target.name === 'sort' || e.target.name === 'coefficient' ? +e.target.value : e.target.value});
    }

    return (
        <form className={classes.form} onSubmit={handleUpdateCarType}>
            <h3>Редактирование</h3>
            <TextField
                name='code'
                className={classes.label}
                type='text'
                id='standard-basic'
                label='code'
                variant='standard'
                value={editCarType.code}
                onChange={handleChange}
            />
            <TextField
                className={classes.label}
                name='coefficient'
                type='number'
                id='standard-basic'
                label='coefficient'
                variant='standard'
                value={editCarType.coefficient}
                onChange={handleChange}
            />
            <TextField
                className={classes.label}
                name='title'
                type='text'
                id='standard-basic'
                label='title'
                variant='standard'
                value={editCarType.title}
                onChange={handleChange}
            />
            <TextField
                className={classes.label}
                name='sort'
                type='number'
                id='standard-basic'
                label='sort'
                variant='standard'
                value={editCarType.sort}
                onChange={handleChange}
            />
            <Button
                type='submit'
                variant='contained'
                color='primary'
                style={{ width: 250, height: 50 }}
            >
                Подтвердить
            </Button>
        </form>
    )
}
