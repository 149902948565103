import React, { useEffect } from 'react'
import DriverProfiles from '../components/DriverProfiles'
import { AuthContext } from '../context/AuthContext'

export default function CarsTypes() {
    const { state, changeDashboardName } = React.useContext(AuthContext)

    useEffect(() => {
        if (state.dashboardName !== 'DriverProfiles') {
            changeDashboardName('DriverProfiles')
        }
    }, [])

    return (
        <DriverProfiles />
    )
}
