import React, { FC } from 'react'
import {Autocomplete, TextField} from '@mui/material';
import {
    DriverProfileSelectFragment,
    GetDriverProfilesSelectListQuery,
    useGetDriverProfilesSelectListQuery
} from './__generated__/DriverProfileSelect';

interface propsType {
    handleProfileSelect: (profileId: number) => void
}
export const DriverProfileSelect: FC<propsType> = ({ handleProfileSelect }) => {
    const [options, setOptions] = React.useState<readonly DriverProfileSelectFragment[]>([])

    const { loading } = useGetDriverProfilesSelectListQuery({
        variables: {
            input: null!
        },
        onCompleted: (data: GetDriverProfilesSelectListQuery) => {
            setOptions([...options, ...data.driverProfiles.items])
        }
    })

    const renderLabel = (profile: DriverProfileSelectFragment): string => {
        return profile.phoneNumber.concat(' (', [profile.lastName, profile.firstName, profile.middleName].filter(Boolean).join(', '), ')');
    }

    return (
        <>
            <Autocomplete
                id='parent-profile-input'
                // value={point}
                isOptionEqualToValue={(option:DriverProfileSelectFragment, value) => option.id === value.id}
                onChange={(_, value) => value && handleProfileSelect(value.id)}
                getOptionLabel={(option) => renderLabel(option)}
                options={options}
                loading={loading}
                renderInput={(params) => <TextField {...params} label='Профиль водителя' />}
            />
        </>
    )
}
