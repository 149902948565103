import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useGetDriverProfilesQuery} from './__generated__/DriverProfiles'
import { DriverProfileList } from '../../__generated__/types'
import { DriverProfile } from './DriverProfile/DriverProfile'
import CreateDriverProfile from './CreateDriverProfile'
import UpdateIcon from '@mui/icons-material/Update'
import { Box, Button, IconButton } from '@mui/material'

export const DriverProfiles = () => {
    const {data, loading, refetch} = useGetDriverProfilesQuery({})
    const [driverProfiles, setDriverProfiles] = useState<DriverProfileList>(null!)
    const [profileWindow, setProfileWindow] = useState<boolean>(false)
    const [createWindow, setCreateWindow] = useState<boolean>(false)
    const [id, setId] = useState<GridCellParams>(null!)

    useEffect(() => {
        if (!loading && data) setDriverProfiles(data?.driverProfiles)
    }, [data,loading])
 
    const handleOpenProfileWindow = (params : GridCellParams) => {
        setId(params)
        setProfileWindow(true)
        
    }
    const closeProfileWindow = () => {
        setProfileWindow(false)
    }
    const closeCreateWindow = () => {
        setCreateWindow(false)
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', width: 50 },
        { field: 'firstName', headerName: 'firstName', width: 150 },
        { field: 'lastName', headerName: 'lastName', width: 150 },
        { field: 'active', headerName: 'active', width: 100 },
        { field: 'phoneNumber', headerName: 'phoneNumber', width: 150 },
        { field: 'telegramAccount', headerName: 'telegram', width: 150 },
        { field: 'carColor', headerName: 'carColor', width: 200 },
        { field: 'carPlateNumber', headerName: 'carPlateNumber', width: 150 },
        { field: 'carDescription', headerName: 'carDescription', width: 150 },

    ]
    if (createWindow) return <CreateDriverProfile  closeWindow={closeCreateWindow} />
    if (profileWindow) return <DriverProfile id={id} closeWindow={closeProfileWindow}/>
    if (loading) return <div>Loading</div>
    if (driverProfiles) return (
        <div style={{ height: 700, width: '100%' }}>
            <Button onClick={() => setCreateWindow(true)}>
                Create Driver Profile
            </Button>
            <Box sx={{display : 'flex', justifyContent: 'end'}}> 
                <IconButton
                    aria-label='delete'
                    size='large'
                    onClick={()=> refetch()}
                >
                    <UpdateIcon fontSize='inherit' />
                </IconButton>
            </Box>
            <DataGrid
                rows={driverProfiles.items}
                columns={columns}
                onCellDoubleClick={(params) => handleOpenProfileWindow(params)}
            />
        </div>
    )
    return <div></div>
}
