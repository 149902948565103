import React, {useEffect} from 'react';
import CarTypes from '../components/CarTypes';
import {AuthContext} from '../context/AuthContext';

export default function CarsTypes() {
    const { state, changeDashboardName } = React.useContext(AuthContext);

    useEffect(() => {
        if (state.dashboardName !== 'CarTypes') {
            changeDashboardName('CarTypes');
        }
    }, []);

    return (

            <CarTypes />
    );
}