import React from 'react';
import { BasePrice } from '../../../__generated__/types';
import Card from '@mui/material/Card';
import { ButtonBase,  CardContent, Popover, Typography } from '@mui/material';

interface ItemType {
    item: BasePrice;
    isOpenEditWindowState: Function;
}
export function PriceItem({item, isOpenEditWindowState}: ItemType) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Card
            sx={{ width: 250, marginTop: 3, marginRight: 3 }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <ButtonBase
                sx={{ width: 1, height: 1 }}
                onClick={() => isOpenEditWindowState(item)}
            >
                <CardContent>
                    <Typography>Title : {item.title}</Typography>
                    <Typography>Code : {item.code}</Typography>
                    <Typography>Price : {item.price}</Typography>
                    <Typography>Min Distance : {item.minDistance}</Typography>
                    <Typography>Max Distance : {item.maxDistance}</Typography>
                </CardContent>
            </ButtonBase>
            <Popover
                id='mouse-over-popover'
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ opacity:0.5, bgcolor:'text-disabled' }}>Click to edit and delete</Typography>
            </Popover>
        </Card>
    );
}
