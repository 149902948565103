import React, { useEffect } from 'react'
import Orders from '../components/Orders'
import { AuthContext } from '../context/AuthContext'

export default function OrdersTypes() {
    const { state, changeDashboardName } = React.useContext(AuthContext)

    useEffect(() => {
        if (state.dashboardName !== 'Заказы') {
            changeDashboardName('Заказы')
        }
    }, [])

    return (
            <Orders />
    )
}
