import React, { FC } from 'react'
import { ModalWrapper } from '../../ModalWrapper/ModalWrapper'
import styles from './ModalPay.module.css'

interface propsType {
    open: boolean
    handleClose: () => void
    handleNavigate: () => void
}

export const ModalPay: FC<propsType> = ({
    open,
    handleClose,
    handleNavigate
}) => {
    return (
        <ModalWrapper open={open} onClose={handleClose}>
            <h3 className={styles.title}>Заказ успешно оформлен</h3>
            <p className={styles.content}>
                Оплатите заказ, пока мы ищем водителя.
            </p>
            <button className={styles.button} onClick={handleNavigate}>
                Оплатить через СБП
            </button>
        </ModalWrapper>
    )
}
