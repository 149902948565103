import React, {Dispatch, SetStateAction} from 'react';
import {Map, Marker} from '@2gis/mapgl/global';
import {Point} from '../__generated__/types';
import {Directions, DirectionsLoadedEvent} from '@2gis/mapgl-directions';

interface MapContextValue {
    mapInstance: Map
    setMapInstance: Dispatch<SetStateAction<Map>>
    directions: Directions
    setDirections: Dispatch<SetStateAction<Directions>>
    directionsLoadedHandler: (directions: DirectionsLoadedEvent) => void
    points: Point[]
    addPointByCoords: (coords: number[]) => void
    addPointByAddress: (point: Point) => void
    markers: Marker[]
    addMarker: (maker: Marker) => void
    distance: number,
    removePoint: (point: Point) => void,
    updateOrderPoints: (points: Point[]) => void
}

export const MapContext = React.createContext<MapContextValue>(null!);