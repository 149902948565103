import * as Types from '../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type GetOrdersQueryVariables = Types.Exact<{
    parentId?: Types.InputMaybe<Types.Scalars['Int']>
    driverId?: Types.InputMaybe<Types.Scalars['Int']>
    statuses?: Types.InputMaybe<
        Array<Types.Scalars['Int']> | Types.Scalars['Int']
    >
    dateFrom?: Types.InputMaybe<Types.Scalars['DateTime']>
    dateTo?: Types.InputMaybe<Types.Scalars['DateTime']>
    paginationInput?: Types.InputMaybe<Types.PaginationInput>
}>

export type GetOrdersQuery = {
    __typename: 'Query'
    orders: {
        __typename: 'OrderList'
        items: Array<{
            __typename: 'Order'
            id?: string | null | undefined
            orderNumber?: number | null | undefined
            paid: boolean
            price: number
            status: string
            comment?: string | null | undefined
            selectedCarType?: number | null | undefined
            selectedDriverType?: number | null | undefined
            carTypes: Array<{
                __typename: 'CarType'
                code: string
                coefficient: number
                id: number
                sort: number
                title: string
                price: string
            }>
            driverProfile?:
                | {
                      __typename: 'DriverProfile'
                      active: boolean
                      carColor?: string | null | undefined
                      carDescription?: string | null | undefined
                      carPlateNumber?: string | null | undefined
                      firstName: string
                      id: number
                      lastName: string
                      middleName?: string | null | undefined
                      phoneNumber: string
                      telegramAccount?: string | null | undefined
                      carTypes: Array<{
                          __typename: 'CarType'
                          code: string
                          coefficient: number
                          id: number
                          price: string
                          sort: number
                          title: string
                      }>
                      driverType?:
                          | {
                                __typename: 'DriverType'
                                code: string
                                id: number
                                sort: number
                                title: string
                            }
                          | null
                          | undefined
                  }
                | null
                | undefined
            parentProfile?:
                | {
                      __typename: 'ParentProfile'
                      firstName: string
                      id: number
                      lastName: string
                      middleName?: string | null | undefined
                      phoneNumber?: string | null | undefined
                      telegramAccount?: string | null | undefined
                      canCallNanny: boolean
                      kids: Array<{
                          __typename: 'KidProfile'
                          age: number
                          id: number
                          name: string
                      }>
                  }
                | null
                | undefined
            driverTypes: Array<{
                __typename: 'DriverType'
                code: string
                id: number
                sort: number
                title: string
            }>
            orderAdditionalServices: Array<{
                __typename: 'OrderAdditionalService'
                additionalService: {
                    __typename: 'AdditionalService'
                    code: string
                    id: number
                    multiple: boolean
                    price: string
                    sort: number
                    title: string
                }
                kids: Array<{
                    __typename: 'KidProfile'
                    age: number
                    id: number
                    name: string
                }>
            }>
            points: Array<{
                __typename: 'Point'
                sort: number
                address?: string | null | undefined
                latitude?: number | null | undefined
                longitude?: number | null | undefined
            }>
            chairs?:
                | {
                      __typename: 'Chairs'
                      boosters?: number | null | undefined
                      chairs?: number | null | undefined
                  }
                | null
                | undefined
            dateTime?:
                | { __typename: 'OrderDateTime'; date: string; time: string }
                | null
                | undefined
        }>
        pagination: {
            __typename: 'Pagination'
            limit?: number | null | undefined
            offset?: number | null | undefined
            totalCount?: number | null | undefined
        }
    }
}

export type CancelOrderMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']
}>

export type CancelOrderMutation = {
    __typename: 'Mutation'
    cancelOrder?:
        | { __typename: 'Order'; id?: string | null | undefined }
        | null
        | undefined
}

export type CompleteOrderMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']
}>

export type CompleteOrderMutation = {
    __typename: 'Mutation'
    completeOrder?:
        | { __typename: 'Order'; id?: string | null | undefined }
        | null
        | undefined
}

export type AssignDriverMutationVariables = Types.Exact<{
    orderId: Types.Scalars['ID']
    driverProfileId: Types.Scalars['Int']
}>

export type AssignDriverMutation = {
    __typename: 'Mutation'
    assignDriver?:
        | { __typename: 'Order'; id?: string | null | undefined }
        | null
        | undefined
}

export const GetOrdersQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getOrdersQuery' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'parentId' }
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Int' }
                    }
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'driverId' }
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Int' }
                    }
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'statuses' }
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'Int' }
                            }
                        }
                    }
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateFrom' }
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'DateTime' }
                    }
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateTo' }
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'DateTime' }
                    }
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'paginationInput' }
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'PaginationInput' }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orders' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'parentId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'parentId' }
                                }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'driverId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'driverId' }
                                }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'statuses' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'statuses' }
                                }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'dateFrom' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'dateFrom' }
                                }
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'dateTo' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'dateTo' }
                                }
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'paginationInput'
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'paginationInput'
                                    }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'orderNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'paid'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'price'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'status'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'carTypes'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'code'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'coefficient'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sort'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'price'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'driverProfile'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'active'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'carColor'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'carDescription'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'carPlateNumber'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'carTypes'
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'code'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'coefficient'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'price'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'sort'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'driverType'
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'code'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'sort'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'firstName'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lastName'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'middleName'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'phoneNumber'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telegramAccount'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'parentProfile'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'firstName'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kids'
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'age'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lastName'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'middleName'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'phoneNumber'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'telegramAccount'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'canCallNanny'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'driverTypes'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'code'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sort'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'orderAdditionalServices'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'additionalService'
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'code'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'multiple'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'price'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'sort'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'kids'
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'age'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id'
                                                                        }
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name'
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'points'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sort'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'address'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'latitude'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'longitude'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'chairs'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'boosters'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'chairs'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'comment'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dateTime'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'date'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'time'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'selectedCarType'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'selectedDriverType'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pagination' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'limit'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'offset'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'totalCount'
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      driverId: // value for 'driverId'
 *      statuses: // value for 'statuses'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useGetOrdersQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetOrdersQuery,
        GetOrdersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(
        GetOrdersQueryDocument,
        options
    )
}
export function useGetOrdersQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetOrdersQuery,
        GetOrdersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(
        GetOrdersQueryDocument,
        options
    )
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>
export type GetOrdersQueryLazyQueryHookResult = ReturnType<
    typeof useGetOrdersQueryLazyQuery
>
export type GetOrdersQueryQueryResult = Apollo.QueryResult<
    GetOrdersQuery,
    GetOrdersQueryVariables
>
export const CancelOrderDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'CancelOrder' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'cancelOrder' },
                        name: { kind: 'Name', value: 'cancelOrder' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type CancelOrderMutationFn = Apollo.MutationFunction<
    CancelOrderMutation,
    CancelOrderMutationVariables
>

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CancelOrderMutation,
        CancelOrderMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CancelOrderMutation,
        CancelOrderMutationVariables
    >(CancelOrderDocument, options)
}
export type CancelOrderMutationHookResult = ReturnType<
    typeof useCancelOrderMutation
>
export type CancelOrderMutationResult =
    Apollo.MutationResult<CancelOrderMutation>
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<
    CancelOrderMutation,
    CancelOrderMutationVariables
>
export const CompleteOrderDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'CompleteOrder' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'completeOrder' },
                        name: { kind: 'Name', value: 'completeOrder' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type CompleteOrderMutationFn = Apollo.MutationFunction<
    CompleteOrderMutation,
    CompleteOrderMutationVariables
>

/**
 * __useCompleteOrderMutation__
 *
 * To run a mutation, you first call `useCompleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOrderMutation, { data, loading, error }] = useCompleteOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CompleteOrderMutation,
        CompleteOrderMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CompleteOrderMutation,
        CompleteOrderMutationVariables
    >(CompleteOrderDocument, options)
}
export type CompleteOrderMutationHookResult = ReturnType<
    typeof useCompleteOrderMutation
>
export type CompleteOrderMutationResult =
    Apollo.MutationResult<CompleteOrderMutation>
export type CompleteOrderMutationOptions = Apollo.BaseMutationOptions<
    CompleteOrderMutation,
    CompleteOrderMutationVariables
>
export const AssignDriverDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'AssignDriver' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderId' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' }
                        }
                    }
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'driverProfileId' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'assignDriver' },
                        name: { kind: 'Name', value: 'assignDriverToOrder' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderId' }
                                }
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'driverProfileId'
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'driverProfileId'
                                    }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type AssignDriverMutationFn = Apollo.MutationFunction<
    AssignDriverMutation,
    AssignDriverMutationVariables
>

/**
 * __useAssignDriverMutation__
 *
 * To run a mutation, you first call `useAssignDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDriverMutation, { data, loading, error }] = useAssignDriverMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      driverProfileId: // value for 'driverProfileId'
 *   },
 * });
 */
export function useAssignDriverMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AssignDriverMutation,
        AssignDriverMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        AssignDriverMutation,
        AssignDriverMutationVariables
    >(AssignDriverDocument, options)
}
export type AssignDriverMutationHookResult = ReturnType<
    typeof useAssignDriverMutation
>
export type AssignDriverMutationResult =
    Apollo.MutationResult<AssignDriverMutation>
export type AssignDriverMutationOptions = Apollo.BaseMutationOptions<
    AssignDriverMutation,
    AssignDriverMutationVariables
>
