import React  from 'react';
import { KidProfile, ParentProfile} from '../../../__generated__/types';
import {Box, IconButton, TextField} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

interface ItemType {
    id: number,
    item: ParentProfile,
    child: KidProfile,
    setCreateParentProfile: (prevState: ParentProfile) => void,
    handleSubtractKid: (id: number) => void
}

export function Kid({ id, item, child, setCreateParentProfile, handleSubtractKid } : ItemType) {
    const handleChange = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.preventDefault();
        let cloneItem = JSON.parse(JSON.stringify(item))
        const findId = cloneItem.kids.findIndex((prod: any) => prod.id === child.id);
        const findChildObject = cloneItem.kids[findId];
        if (e.target.name === 'name') {
            findChildObject[e.target.name] = e.target.value;
        }
        if (e.target.name === 'age') {
            findChildObject[e.target.name] = +e.target.value;
        }
        setCreateParentProfile(cloneItem);
    }

    return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
                name='name'
                type='text'
                id='standard-basic'
                label='name'
                variant='standard'
                value={child.name}
                onChange={handleChange}
                required
            />
            <TextField
                style={{ marginLeft: 10}}
                name='age'
                type='number'
                id='standard-basic'
                label='age'
                variant='standard'
                value={child.age}
                onChange={handleChange}
                required
            />
            <IconButton
                color='primary'
                onClick={() => handleSubtractKid(child.id)}
            >
                <RemoveCircleOutlineIcon />
            </IconButton>
        </Box>
    )
}
