import * as Types from '../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type DriverTypesQueryVariables = Types.Exact<{ [key: string]: never }>

export type DriverTypesQuery = {
    __typename: 'Query'
    driverTypes: Array<{
        __typename: 'DriverType'
        id: number
        code: string
        title: string
        sort: number
    }>
}

export type CreateDriverTypeMutationVariables = Types.Exact<{
    input: Types.CreateDriverTypeInput
}>

export type CreateDriverTypeMutation = {
    __typename: 'Mutation'
    createDriverType: {
        __typename: 'DriverType'
        id: number
        code: string
        sort: number
        title: string
    }
}

export type UpdateDriverTypeMutationVariables = Types.Exact<{
    input: Types.UpdateDriverTypeInput
}>

export type UpdateDriverTypeMutation = {
    __typename: 'Mutation'
    updateDriverType: {
        __typename: 'DriverType'
        id: number
        code: string
        sort: number
        title: string
    }
}

export type DeleteDriverTypeMutationVariables = Types.Exact<{
    id: Types.Scalars['Int']
}>

export type DeleteDriverTypeMutation = {
    __typename: 'Mutation'
    deleteDriverType: boolean
}

export const DriverTypesQueryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'DriverTypesQuery' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'driverTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useDriverTypesQuery__
 *
 * To run a query within a React component, call `useDriverTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDriverTypesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        DriverTypesQuery,
        DriverTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<DriverTypesQuery, DriverTypesQueryVariables>(
        DriverTypesQueryDocument,
        options
    )
}
export function useDriverTypesQueryLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        DriverTypesQuery,
        DriverTypesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<DriverTypesQuery, DriverTypesQueryVariables>(
        DriverTypesQueryDocument,
        options
    )
}
export type DriverTypesQueryHookResult = ReturnType<typeof useDriverTypesQuery>
export type DriverTypesQueryLazyQueryHookResult = ReturnType<
    typeof useDriverTypesQueryLazyQuery
>
export type DriverTypesQueryQueryResult = Apollo.QueryResult<
    DriverTypesQuery,
    DriverTypesQueryVariables
>
export const CreateDriverTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'CreateDriverType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'CreateDriverTypeInput'
                            }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'createDriverType' },
                        name: { kind: 'Name', value: 'createDriverType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type CreateDriverTypeMutationFn = Apollo.MutationFunction<
    CreateDriverTypeMutation,
    CreateDriverTypeMutationVariables
>

/**
 * __useCreateDriverTypeMutation__
 *
 * To run a mutation, you first call `useCreateDriverTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverTypeMutation, { data, loading, error }] = useCreateDriverTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateDriverTypeMutation,
        CreateDriverTypeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateDriverTypeMutation,
        CreateDriverTypeMutationVariables
    >(CreateDriverTypeDocument, options)
}
export type CreateDriverTypeMutationHookResult = ReturnType<
    typeof useCreateDriverTypeMutation
>
export type CreateDriverTypeMutationResult =
    Apollo.MutationResult<CreateDriverTypeMutation>
export type CreateDriverTypeMutationOptions = Apollo.BaseMutationOptions<
    CreateDriverTypeMutation,
    CreateDriverTypeMutationVariables
>
export const UpdateDriverTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'UpdateDriverType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'UpdateDriverTypeInput'
                            }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'updateDriverType' },
                        name: { kind: 'Name', value: 'updateDriverType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type UpdateDriverTypeMutationFn = Apollo.MutationFunction<
    UpdateDriverTypeMutation,
    UpdateDriverTypeMutationVariables
>

/**
 * __useUpdateDriverTypeMutation__
 *
 * To run a mutation, you first call `useUpdateDriverTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverTypeMutation, { data, loading, error }] = useUpdateDriverTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateDriverTypeMutation,
        UpdateDriverTypeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateDriverTypeMutation,
        UpdateDriverTypeMutationVariables
    >(UpdateDriverTypeDocument, options)
}
export type UpdateDriverTypeMutationHookResult = ReturnType<
    typeof useUpdateDriverTypeMutation
>
export type UpdateDriverTypeMutationResult =
    Apollo.MutationResult<UpdateDriverTypeMutation>
export type UpdateDriverTypeMutationOptions = Apollo.BaseMutationOptions<
    UpdateDriverTypeMutation,
    UpdateDriverTypeMutationVariables
>
export const DeleteDriverTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'DeleteDriverType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'deleteDriverType' },
                        name: { kind: 'Name', value: 'deleteDriverType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type DeleteDriverTypeMutationFn = Apollo.MutationFunction<
    DeleteDriverTypeMutation,
    DeleteDriverTypeMutationVariables
>

/**
 * __useDeleteDriverTypeMutation__
 *
 * To run a mutation, you first call `useDeleteDriverTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverTypeMutation, { data, loading, error }] = useDeleteDriverTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDriverTypeMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteDriverTypeMutation,
        DeleteDriverTypeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteDriverTypeMutation,
        DeleteDriverTypeMutationVariables
    >(DeleteDriverTypeDocument, options)
}
export type DeleteDriverTypeMutationHookResult = ReturnType<
    typeof useDeleteDriverTypeMutation
>
export type DeleteDriverTypeMutationResult =
    Apollo.MutationResult<DeleteDriverTypeMutation>
export type DeleteDriverTypeMutationOptions = Apollo.BaseMutationOptions<
    DeleteDriverTypeMutation,
    DeleteDriverTypeMutationVariables
>
