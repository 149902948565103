import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { useAddressSuggestionsQuery} from '../Address/__generated__/Address'
import { MapContext } from '../../../context/MapContext'
import { Point } from '../../../__generated__/types'
import styles from './AddAddress.module.css'
import { load } from '@2gis/mapgl'

export const AddAddress = ({ point }: { point: Point | null }) => {
    const { addPointByAddress, addMarker, mapInstance } =
        React.useContext(MapContext)
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState<readonly Point[]>([])
    const [loading, setLoading] = React.useState<boolean>(false)

    const { refetch } = useAddressSuggestionsQuery({
        fetchPolicy: 'standby',
        variables: {
            input: null!
        },
        onCompleted: (data: { searchAddress: any }) => {
            setOptions([...options, ...data.searchAddress])
            setLoading(false)
        }
    })

    return (
        <div className={styles.addAddress}>
            <div style={{ display: 'flex' }}>
                <Autocomplete
                    style={{ width: '90%' }}
                    id='standard-basic'
                    open={open}
                    value={point}
                    onInputChange={(_, value, reason) => {
                        if (reason === 'input' && value.length > 3) {
                            setOpen(true)
                            setLoading(true)
                            refetch({ input: value })
                        }
                    }}
                    onClose={() => {
                        setOpen(false)
                    }}
                    onChange={(_, value) => {
                        value && addPointByAddress(value)
                        const arr: Array<number> = []
                        if (value)
                            if (
                                typeof value.longitude == 'number' &&
                                typeof value.latitude == 'number'
                            ) {
                                arr.push(value.longitude)
                                arr.push(value.latitude)
                            }
                        if (arr)
                            load().then((mapglAPI) => {
                                addMarker(
                                    new mapglAPI.Marker(mapInstance, {
                                        coordinates: arr,
                                        icon: 'https://docs.2gis.com/img/dotMarker.svg'
                                    })
                                )
                            })
                    }}
                    getOptionLabel={(option) => option.address || ''}
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='standard'
                            placeholder='Добавить остановку'
                            multiline
                            minRows={1}
                            maxRows={2}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                style: {
                                    fontSize: '16px',
                                    lineHeight: '1',
                                    paddingRight: '0'
                                },
                                endAdornment: null
                            }}
                        />
                    )}
                />
            </div>
        </div>
    )
}
