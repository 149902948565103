import {
    DataGrid,
    GridCellParams,
    GridColDef,
    GridValueGetterParams
} from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { OrderList, Point } from '../../__generated__/types'
import OrderItem from './OrderItem'
import { useGetOrdersQuery } from './__generated__/Orders'

const getDriverTypesTitle = (params: GridValueGetterParams) => {
    return params.row.driverTypes[0].title
}
const getPaid = (params: GridValueGetterParams) => {
    return params.row.paid ? 'Оплачен' : 'Не оплачен'
}
const getCarTypesTitle = (params: GridValueGetterParams) => {
    return params.row.carTypes[0].title
}
const getChairsBoosters = (params: GridValueGetterParams) => {
    return params.row.chairs.boosters
}
const getDate = (params: GridValueGetterParams) => {
    if (params.row.dateTime != null) {
        return params.row.dateTime.date + ' ' + params.row.dateTime.time
    } else return 'null'
}
const getChairs = (params: GridValueGetterParams) => {
    return params.row.chairs.chairs
}
const getDriverName = (params: GridValueGetterParams) => {
    if (params.row.driverProfile) {
        return (
            params.row.driverProfile.firstName +
            ' ' +
            params.row.driverProfile.lastName
        )
    } else return 'null'
}
const getParentName = (params: GridValueGetterParams) => {
    return (
        params.row.parentProfile.firstName +
        ' ' +
        params.row.parentProfile.lastName
    )
}
const getPoints = (params: GridValueGetterParams) => {
    return params.row.points.reduce(
        (prev: string, current: Point) =>
            prev ? prev + ' -> ' + current.address : current.address,
        ''
    )
}
const columns: GridColDef[] = [
    { field: 'orderNumber', headerName: '№', width: 100 },
    { field: 'price', headerName: 'Стоимость', width: 100 },
    { field: 'status', headerName: 'Статус', width: 100 },
    { field: 'paid', headerName: 'Оплата', width: 100,
        valueGetter: getPaid },
    {
        field: 'date',
        headerName: 'Дата поездки',
        width: 200,
        valueGetter: getDate
    },
    {
        field: 'parentName',
        headerName: 'Родитель',
        width: 200,
        valueGetter: getParentName
    },
    {
        field: 'driverName',
        headerName: 'Автоняня',
        width: 200,
        valueGetter: getDriverName
    },
    {
        field: 'carTypes',
        headerName: 'carTypeTitle',
        width: 150,
        valueGetter: getCarTypesTitle
    },
    {
        field: 'driverTypes',
        headerName: 'driverTypeTitle',
        width: 150,
        valueGetter: getDriverTypesTitle
    },
    {
        field: 'boosters',
        headerName: 'boosters',
        width: 100,
        valueGetter: getChairsBoosters
    },
    {
        field: 'chairs',
        headerName: 'chairs',
        width: 50,
        valueGetter: getChairs
    },
    {
        field: 'points',
        headerName: 'points',
        width: 400,
        valueGetter: getPoints
    },
    {
        field: 'ordersAdditionalServices',
        headerName: 'ordersAdditionalServicesegram',
        width: 100
    },
    { field: 'comment', headerName: 'comment', width: 400 }
]

export const Orders = () => {
    const { data, loading } = useGetOrdersQuery({})
    const [orders, setOrders] = useState<OrderList>(null!)
    const [id, setId] = useState<GridCellParams>(null!)
    const [orderWindow, setOrderWindow] = useState<boolean>(false)
    console.log(data)

    useEffect(() => {
        if (!loading && data) setOrders(data.orders)
    }, [data, loading])

    const handleOpenOrderItemWindow = (params: GridCellParams) => {
        setId(params)
        setOrderWindow(true)
    }
    const closeProfileWindow = () => {
        setOrderWindow(false)
    }
    if (orderWindow)
        return <OrderItem id={id} closeWindow={closeProfileWindow} />
    if (loading) return <div>Loading</div>
    if (orders)
        return (
                <DataGrid
                    autoHeight
                    rows={orders.items}
                    columns={columns}
                    onCellDoubleClick={(params) =>
                        handleOpenOrderItemWindow(params)
                    }
                />
        )
    return <div>No orders</div>
}
