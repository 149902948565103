import * as Types from '../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type OrderFragment = {
    __typename: 'Order'
    id?: string | null | undefined
    selectedCarType?: number | null | undefined
    selectedDriverType?: number | null | undefined
    comment?: string | null | undefined
    price: number
    paid: boolean
    orderNumber?: number | null | undefined
    carTypes: Array<{
        __typename: 'CarType'
        id: number
        price: string
        title: string
        code: string
        coefficient: number
        sort: number
    }>
    driverTypes: Array<{
        __typename: 'DriverType'
        code: string
        id: number
        sort: number
        title: string
    }>
    orderAdditionalServices: Array<{
        __typename: 'OrderAdditionalService'
        additionalService: {
            __typename: 'AdditionalService'
            code: string
            id: number
            multiple: boolean
            price: string
            sort: number
            title: string
        }
        kids: Array<{ __typename: 'KidProfile'; age: number; id: number }>
    }>
    dateTime?:
        | { __typename: 'OrderDateTime'; date: string; time: string }
        | null
        | undefined
    chairs?:
        | {
              __typename: 'Chairs'
              boosters?: number | null | undefined
              chairs?: number | null | undefined
          }
        | null
        | undefined
    points: Array<{
        __typename: 'Point'
        address?: string | null | undefined
        longitude?: number | null | undefined
        latitude?: number | null | undefined
        sort: number
    }>
    parentProfile?:
        | {
              __typename: 'ParentProfile'
              id: number
              firstName: string
              lastName: string
              middleName?: string | null | undefined
              phoneNumber?: string | null | undefined
              telegramAccount?: string | null | undefined
              kids: Array<{
                  __typename: 'KidProfile'
                  id: number
                  name: string
                  age: number
              }>
          }
        | null
        | undefined
}

export type CalculateOrderQueryVariables = Types.Exact<{
    input?: Types.InputMaybe<Types.CalculateOrderInput>
}>

export type CalculateOrderQuery = {
    __typename: 'Query'
    calculate: {
        __typename: 'Order'
        id?: string | null | undefined
        selectedCarType?: number | null | undefined
        selectedDriverType?: number | null | undefined
        comment?: string | null | undefined
        price: number
        paid: boolean
        orderNumber?: number | null | undefined
        carTypes: Array<{
            __typename: 'CarType'
            id: number
            price: string
            title: string
            code: string
            coefficient: number
            sort: number
        }>
        driverTypes: Array<{
            __typename: 'DriverType'
            code: string
            id: number
            sort: number
            title: string
        }>
        orderAdditionalServices: Array<{
            __typename: 'OrderAdditionalService'
            additionalService: {
                __typename: 'AdditionalService'
                code: string
                id: number
                multiple: boolean
                price: string
                sort: number
                title: string
            }
            kids: Array<{ __typename: 'KidProfile'; age: number; id: number }>
        }>
        dateTime?:
            | { __typename: 'OrderDateTime'; date: string; time: string }
            | null
            | undefined
        chairs?:
            | {
                  __typename: 'Chairs'
                  boosters?: number | null | undefined
                  chairs?: number | null | undefined
              }
            | null
            | undefined
        points: Array<{
            __typename: 'Point'
            address?: string | null | undefined
            longitude?: number | null | undefined
            latitude?: number | null | undefined
            sort: number
        }>
        parentProfile?:
            | {
                  __typename: 'ParentProfile'
                  id: number
                  firstName: string
                  lastName: string
                  middleName?: string | null | undefined
                  phoneNumber?: string | null | undefined
                  telegramAccount?: string | null | undefined
                  kids: Array<{
                      __typename: 'KidProfile'
                      id: number
                      name: string
                      age: number
                  }>
              }
            | null
            | undefined
    }
}

export type PlaceOrderMutationVariables = Types.Exact<{
    input: Types.PlaceOrderInput
}>

export type PlaceOrderMutation = {
    __typename: 'Mutation'
    placeOrder: {
        __typename: 'Order'
        id?: string | null | undefined
        comment?: string | null | undefined
        price: number
        selectedCarType?: number | null | undefined
        selectedDriverType?: number | null | undefined
        carTypes: Array<{
            __typename: 'CarType'
            code: string
            coefficient: number
            id: number
            price: string
            sort: number
            title: string
        }>
        chairs?:
            | {
                  __typename: 'Chairs'
                  boosters?: number | null | undefined
                  chairs?: number | null | undefined
              }
            | null
            | undefined
        dateTime?:
            | { __typename: 'OrderDateTime'; date: string; time: string }
            | null
            | undefined
        driverProfile?:
            | {
                  __typename: 'DriverProfile'
                  active: boolean
                  carColor?: string | null | undefined
                  carDescription?: string | null | undefined
                  carPlateNumber?: string | null | undefined
                  firstName: string
                  id: number
                  lastName: string
                  middleName?: string | null | undefined
                  phoneNumber: string
                  telegramAccount?: string | null | undefined
                  carTypes: Array<{
                      __typename: 'CarType'
                      code: string
                      coefficient: number
                      id: number
                      price: string
                      sort: number
                      title: string
                  }>
                  driverType?:
                      | {
                            __typename: 'DriverType'
                            code: string
                            title: string
                            id: number
                            sort: number
                        }
                      | null
                      | undefined
              }
            | null
            | undefined
        driverTypes: Array<{
            __typename: 'DriverType'
            code: string
            id: number
            sort: number
            title: string
        }>
        orderAdditionalServices: Array<{
            __typename: 'OrderAdditionalService'
            additionalService: {
                __typename: 'AdditionalService'
                code: string
                id: number
                sort: number
                title: string
                multiple: boolean
                price: string
            }
            kids: Array<{
                __typename: 'KidProfile'
                age: number
                id: number
                name: string
            }>
        }>
        parentProfile?:
            | {
                  __typename: 'ParentProfile'
                  id: number
                  firstName: string
                  lastName: string
                  middleName?: string | null | undefined
                  phoneNumber?: string | null | undefined
                  telegramAccount?: string | null | undefined
                  kids: Array<{
                      __typename: 'KidProfile'
                      id: number
                      age: number
                      name: string
                  }>
              }
            | null
            | undefined
        points: Array<{
            __typename: 'Point'
            address?: string | null | undefined
            sort: number
            latitude?: number | null | undefined
            longitude?: number | null | undefined
        }>
        promoCode?:
            | {
                  __typename: 'OrderPromoCode'
                  applied: boolean
                  code: string
                  message: string
              }
            | null
            | undefined
    }
}

export type GetpaymentLinkQueryVariables = Types.Exact<{
    orderId: Types.Scalars['ID']
}>

export type GetpaymentLinkQuery = { __typename: 'Query'; paymentLink: string }

export const OrderFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Order' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Order' }
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'carTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coefficient' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'driverTypes' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'code' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'orderAdditionalServices'
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'additionalService'
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'code'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'multiple'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'price'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sort'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kids' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'age'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTime' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'date' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'time' }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chairs' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'boosters' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chairs' }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'points' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'longitude' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'latitude' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sort' }
                                }
                            ]
                        }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentProfile' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phoneNumber' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'telegramAccount'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kids' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'age'
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selectedCarType' }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selectedDriverType' }
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'paid' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orderNumber' }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export const CalculateOrderDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CalculateOrder' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'CalculateOrderInput' }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'calculate' },
                        name: { kind: 'Name', value: 'calculate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'Order' }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...OrderFragmentDoc.definitions
    ]
} as unknown as DocumentNode

/**
 * __useCalculateOrderQuery__
 *
 * To run a query within a React component, call `useCalculateOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateOrderQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CalculateOrderQuery,
        CalculateOrderQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<CalculateOrderQuery, CalculateOrderQueryVariables>(
        CalculateOrderDocument,
        options
    )
}
export function useCalculateOrderLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CalculateOrderQuery,
        CalculateOrderQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        CalculateOrderQuery,
        CalculateOrderQueryVariables
    >(CalculateOrderDocument, options)
}
export type CalculateOrderQueryHookResult = ReturnType<
    typeof useCalculateOrderQuery
>
export type CalculateOrderLazyQueryHookResult = ReturnType<
    typeof useCalculateOrderLazyQuery
>
export type CalculateOrderQueryResult = Apollo.QueryResult<
    CalculateOrderQuery,
    CalculateOrderQueryVariables
>
export const PlaceOrderDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'placeOrder' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PlaceOrderInput' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'placeOrder' },
                        name: { kind: 'Name', value: 'placeOrder' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'carTypes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'code'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'coefficient'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'price'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sort'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chairs' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'boosters'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'chairs'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'comment' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateTime' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'date'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'time'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'driverProfile'
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'active'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'carColor'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'carDescription'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'carPlateNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'carTypes'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'code'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'coefficient'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'price'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sort'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'driverType'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'code'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sort'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'firstName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lastName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'middleName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'phoneNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'telegramAccount'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'driverTypes'
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'code'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sort'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'title'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'orderAdditionalServices'
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'additionalService'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'code'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'sort'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'multiple'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'price'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'kids'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'age'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name'
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'parentProfile'
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'firstName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lastName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'middleName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'phoneNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'telegramAccount'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'kids'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'age'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name'
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'points' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'address'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'sort'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'latitude'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'longitude'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'promoCode' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'applied'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'code'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'message'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'selectedCarType'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'selectedDriverType'
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type PlaceOrderMutationFn = Apollo.MutationFunction<
    PlaceOrderMutation,
    PlaceOrderMutationVariables
>

/**
 * __usePlaceOrderMutation__
 *
 * To run a mutation, you first call `usePlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderMutation, { data, loading, error }] = usePlaceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceOrderMutation(
    baseOptions?: Apollo.MutationHookOptions<
        PlaceOrderMutation,
        PlaceOrderMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(
        PlaceOrderDocument,
        options
    )
}
export type PlaceOrderMutationHookResult = ReturnType<
    typeof usePlaceOrderMutation
>
export type PlaceOrderMutationResult = Apollo.MutationResult<PlaceOrderMutation>
export type PlaceOrderMutationOptions = Apollo.BaseMutationOptions<
    PlaceOrderMutation,
    PlaceOrderMutationVariables
>
export const GetpaymentLinkDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getpaymentLink' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderId' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'paymentLink' },
                        name: { kind: 'Name', value: 'paymentLink' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'orderId' }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useGetpaymentLinkQuery__
 *
 * To run a query within a React component, call `useGetpaymentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetpaymentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetpaymentLinkQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetpaymentLinkQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetpaymentLinkQuery,
        GetpaymentLinkQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetpaymentLinkQuery, GetpaymentLinkQueryVariables>(
        GetpaymentLinkDocument,
        options
    )
}
export function useGetpaymentLinkLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetpaymentLinkQuery,
        GetpaymentLinkQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetpaymentLinkQuery,
        GetpaymentLinkQueryVariables
    >(GetpaymentLinkDocument, options)
}
export type GetpaymentLinkQueryHookResult = ReturnType<
    typeof useGetpaymentLinkQuery
>
export type GetpaymentLinkLazyQueryHookResult = ReturnType<
    typeof useGetpaymentLinkLazyQuery
>
export type GetpaymentLinkQueryResult = Apollo.QueryResult<
    GetpaymentLinkQuery,
    GetpaymentLinkQueryVariables
>
