import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import SignIn from '../pages/SignIn';
import React from 'react';
import {AuthContext} from '../context/AuthContext';
import CarTypes from '../pages/CarsTypes';
import Main from '../pages/Main';
import DriveTypes from '../pages/DriveTypes';
import Calculator from '../pages/Calculator';
import Loader from '../pages/Loader';
import ParentProfile from '../pages/ParentProfile';
import BasePrices  from '../pages/BasePrices';
import DriverProfiles  from '../pages/DriverProfiles';
import Orders from '../pages/Orders'
import Users from '../pages/Users';
import ParentOrder from '../pages/ParentOrder';


export default function RootRouter() {

    function RequireAuth({children}: { children: JSX.Element }) {
        const {state} = React.useContext(AuthContext);
        return state.userToken ? children : <Navigate to='/login' replace/>;
    }

    function AuthRedirect({children}: { children: JSX.Element }) {
        const {state} = React.useContext(AuthContext);
        return state.userToken ? <Navigate to='/' replace/> : children;
    }

    const {state, bootstrap} = React.useContext(AuthContext)

    React.useEffect(() => {
        bootstrap();
    }, []);

    return (
        <>
            {state.isLoading ? <Loader/> :
                <BrowserRouter>
                    <Routes>
                        <Route path='/login' element={<AuthRedirect><SignIn/></AuthRedirect>}/>
                        <Route path='/' element={
                            <RequireAuth><Main/></RequireAuth>}>
                            <Route path='driver-profiles' element={<DriverProfiles/>}/>
                            <Route path='parent-profiles' element={<ParentProfile/>}/>
                            <Route path='calculator' element={<Calculator/>}/>
                            <Route path='base-prices' element={<BasePrices/>}/>
                            <Route path='orders' element={<Orders/>}/>
                            <Route path='car-types' element={<CarTypes/>}/>
                            <Route path='driver-types' element={<DriveTypes/>}/>
                            <Route path='users' element={<Users/>}/>
                            <Route path='parent-order' element={<ParentOrder/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            }
        </>
    )
}