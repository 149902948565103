import * as Types from '../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type DriverProfileSelectFragment = {
    __typename: 'DriverProfile'
    firstName: string
    id: number
    lastName: string
    middleName?: string | null | undefined
    phoneNumber: string
}

export type GetDriverProfilesSelectListQueryVariables = Types.Exact<{
    input?: Types.InputMaybe<Types.PaginationInput>
}>

export type GetDriverProfilesSelectListQuery = {
    __typename: 'Query'
    driverProfiles: {
        __typename: 'DriverProfileList'
        items: Array<{
            __typename: 'DriverProfile'
            firstName: string
            id: number
            lastName: string
            middleName?: string | null | undefined
            phoneNumber: string
        }>
        pagination: {
            __typename: 'Pagination'
            limit?: number | null | undefined
            offset?: number | null | undefined
            totalCount?: number | null | undefined
        }
    }
}

export const DriverProfileSelectFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DriverProfileSelect' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DriverProfile' }
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' }
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'middleName' }
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export const GetDriverProfilesSelectListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDriverProfilesSelectList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'PaginationInput' }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'driverProfiles' },
                        name: { kind: 'Name', value: 'driverProfiles' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'paginationInput'
                                },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'DriverProfileSelect'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pagination' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'limit'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'offset'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'totalCount'
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        },
        ...DriverProfileSelectFragmentDoc.definitions
    ]
} as unknown as DocumentNode

/**
 * __useGetDriverProfilesSelectListQuery__
 *
 * To run a query within a React component, call `useGetDriverProfilesSelectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverProfilesSelectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverProfilesSelectListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDriverProfilesSelectListQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetDriverProfilesSelectListQuery,
        GetDriverProfilesSelectListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        GetDriverProfilesSelectListQuery,
        GetDriverProfilesSelectListQueryVariables
    >(GetDriverProfilesSelectListDocument, options)
}
export function useGetDriverProfilesSelectListLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetDriverProfilesSelectListQuery,
        GetDriverProfilesSelectListQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        GetDriverProfilesSelectListQuery,
        GetDriverProfilesSelectListQueryVariables
    >(GetDriverProfilesSelectListDocument, options)
}
export type GetDriverProfilesSelectListQueryHookResult = ReturnType<
    typeof useGetDriverProfilesSelectListQuery
>
export type GetDriverProfilesSelectListLazyQueryHookResult = ReturnType<
    typeof useGetDriverProfilesSelectListLazyQuery
>
export type GetDriverProfilesSelectListQueryResult = Apollo.QueryResult<
    GetDriverProfilesSelectListQuery,
    GetDriverProfilesSelectListQueryVariables
>
