import React, { useEffect } from 'react'
import {Users} from '../components/Users'
import { AuthContext } from '../context/AuthContext'

export default function BasePrices() {
    const { state, changeDashboardName } = React.useContext(AuthContext)

    useEffect(() => {
        if (state.dashboardName !== 'Пользователи') {
            changeDashboardName('Пользователи')
        }
    }, [])
    return (
            <Users />
    )
}
