import { Box, Button, IconButton, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { useState } from 'react'
import {useCreateDriverProfileMutation} from '../__generated__/DriverProfiles'

const useStyles = makeStyles({
    form: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    label: {
        width: '100%',
        margin: 10
    },
    h4: {
        textAlign: 'left',
        paddingLeft: '30px'
    }
})
export const CreateDriverProfile = ({
    closeWindow
}: {
    closeWindow: () => void
}) => {
    const classes = useStyles()
    const [carColor, setCarColor] = useState<string>('')
    const [carDescription, setCarDescription] = useState<string>('')
    const [carPlateNumber, setCarPlateNumber] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [middleName, setMiddleName] = useState<string>()
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [createDriverProfile] = useCreateDriverProfileMutation()

    const handleCreateDriverProfile = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const data = {
            active: true,
            carColor: carColor,
            carDescription: carDescription,
            carPlateNumber: carPlateNumber,
            carTypeIds: [1,2,3],
            driverTypeId: 2,
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            phoneNumber: phoneNumber
        }
        createDriverProfile({ variables: { input: data } })
    }
    
    return (
        <div>
            <Box>
                <h3 className={classes.h4}>Создание профиля водителя</h3>
                <form
                    className={classes.form}
                    onSubmit={handleCreateDriverProfile}
                >
                    <TextField
                        className={classes.label}
                        name='lastName'
                        type='text'
                        id='standard-basic'
                        label='Фамилия'
                        variant='outlined'
                        value={lastName}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setLastName(e.target.value)
                        }}
                    />
                    <TextField
                        name='firstName'
                        className={classes.label}
                        type='text'
                        id='standard-basic'
                        label='Имя'
                        variant='outlined'
                        value={firstName}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setFirstName(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='middleName'
                        type='text'
                        id='standard-basic'
                        label='Отчество'
                        variant='outlined'
                        value={middleName ? middleName : ''}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setMiddleName(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='phoneNumber'
                        type='text'
                        id='standard-basic'
                        label='Номер телефона +79991112233'
                        variant='outlined'
                        value={phoneNumber}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setPhoneNumber(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='carColor'
                        type='text'
                        id='standard-basic'
                        label='Цвет авто'
                        variant='outlined'
                        value={carColor}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setCarColor(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='carDescription'
                        type='text'
                        id='standard-basic'
                        label='Марка и модель авто'
                        variant='outlined'
                        value={carDescription}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setCarDescription(e.target.value)
                        }}
                    />
                    <TextField
                        className={classes.label}
                        name='carPlateNumber'
                        type='text'
                        id='standard-basic'
                        label='Номер автомобиля'
                        variant='outlined'
                        value={carPlateNumber}
                        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.preventDefault()
                            setCarPlateNumber(e.target.value)
                        }}
                    />
                    <Box>
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            style={{ width: 250, height: 50, marginRight: 5 }}
                        >
                            Создать
                        </Button>
                        <IconButton
                            aria-label='delete'
                            size='large'
                            onClick={closeWindow}
                        >
                            <CancelRoundedIcon fontSize='inherit' />
                        </IconButton>
                    </Box>
                </form>
            </Box>
        </div>
    )
}
