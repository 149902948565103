import React from 'react'
import MapLayer from '../components/MapLayer'
import OrderPanel from '../components/OrderPanel'
import RouteBuilder from '../components/RouteBuilder'
import MapProvider from '../components/MapProvider'

export default function ParentOrder() {
    return (
        <>
            <MapProvider>
                <>
                    <RouteBuilder />
                    <MapLayer />
                    <OrderPanel />
                </>
            </MapProvider>
        </>
    )
}
