import React, {useEffect, useState} from 'react';
import { makeStyles } from '@mui/styles';
import {AuthContext} from '../../context/AuthContext';
import {
    AdminCreateParentProfileInput,
    ParentProfile,
    CreateKidProfileInput,
    InputMaybe,
    AdminUpdateParentProfileInput,
    UpdateKidProfileInput, KidProfile
} from '../../__generated__/types';
import {
    useCreateParentProfileMutation,
    useDeleteParentProfileMutation,
    useUpdateParentProfileMutation, useParentProfilesQuery
} from './__generated__/ParentProfile';
import Profile from './Profile';
import ProfileForm from './ProfileForm';
import { Button, FormGroup, TextField, Box, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const useStyles = makeStyles({
    createWindow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: 10
    },
    editModal: {
        top: 0,
        left: '20%',
        width: '450px',
        height: '50%',
        marginTop: '190px',
        position: 'absolute',
        backgroundColor: '#EFEFEF',
        zIndex: '100',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: 10
    },
    closeButton: {
        display: 'flex'
    }
});

const testParentProfileArray: ParentProfile[] = [];

const emptyParentProfile: ParentProfile = {
    firstName: '',
    canCallNanny: false,
    id: 0,
    kids: [ {id: 1,  name: '', age: 0 } ],
    lastName: '',
    middleName: '',
    phoneNumber: '',
    telegramAccount: ''
};

export function ParentProfilePage() {
    const classes = useStyles();
    const { state, changeDashboardName } = React.useContext(AuthContext);
    const [tabsParentProfile, setTabsParentProfile] = React.useState<ParentProfile[]>(testParentProfileArray);
    const [isOpenAllProfiles, setIsOpenAllProfiles] = useState<boolean>(true);
    const [activeIdProfile, setActiveIdProfile] = useState<string>('');
    const [createProfile, setCreateParentProfile] = useState<ParentProfile>(emptyParentProfile);
    const [activeEditParentProfile, setActiveEditParentProfile] = useState<ParentProfile>(testParentProfileArray[0]);

    const [isOpenEditForm, setIsOpenEditForm] = useState<boolean>(false);
    const [editParentProfile, setEditParentProfile] = useState<ParentProfile>(emptyParentProfile);

    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isOpenCreateWindow, setIsOpenCreateWindow] = useState<boolean>(false);
    const [isOpenOneProfileWindow, setIsOpenOneProfileWindow] = useState<boolean>(false);

    const [ createParentProfile ] = useCreateParentProfileMutation();
    const [ deleteParentProfile ] = useDeleteParentProfileMutation();
    const [ updateParentProfile ] = useUpdateParentProfileMutation();

    const {loading, data} = useParentProfilesQuery();
    let parentProfilesData;

    const handleIdProfileChange = (e: React.FocusEvent<HTMLInputElement>): void => {
        e.preventDefault();
        setActiveIdProfile(e.target.value);
        if (isNaN(+e.target.value)) {
            console.log('Введено не число');
        } else {
            if (+e.target.value > 0) {
                setIsDisabled(false);
            } else {
                setIsDisabled(true);
            }

        }
    }

    const handleOpenEditForm = (item: ParentProfile): void => {
        setIsOpenEditForm(true);
        setEditParentProfile(item);
    }

    const handleOpenOneProfile = (id: string): void => {
        const tabs = [...tabsParentProfile];
        const findId = tabs.findIndex((prod: any) => prod.id === +id);
        const profile = tabs[findId];
        setActiveEditParentProfile(profile);
        setIsOpenCreateWindow(false);
        setIsOpenOneProfileWindow(true);
        setIsOpenAllProfiles(false);
    }

    const handleCreateProfileParent = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let kidsArray:  InputMaybe<CreateKidProfileInput>[] = [];
        createProfile.kids.forEach((item) => {
            kidsArray.push(
                {age: item.age, name: item.name})
        })
        console.log(createProfile);
        const newData: AdminCreateParentProfileInput = {
            firstName: createProfile.firstName,
            kids: kidsArray,
            lastName: createProfile.lastName,
            middleName: createProfile.middleName,
            phoneNumber: createProfile.phoneNumber + '',
            telegramAccount: createProfile.telegramAccount + '',
        }
        createParentProfile({
            variables: {
                input: newData
            }
        }).then((response: any) => {
            if (response.data) {
                const profile = response.data.createParentProfile;
                const tabs = [ ...tabsParentProfile ];
                tabs.push(profile);
                setTabsParentProfile(tabs);
            }
            setIsOpenCreateWindow(false);
            setIsOpenAllProfiles(true);
        })
            .catch((error: any) => {
                console.error(error);
            });
    }

    const handleUpdateProfileParent = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let kidsArray:  InputMaybe<UpdateKidProfileInput>[] = [];
        editParentProfile.kids.forEach((item) => {
            kidsArray.push(
                {age: item.age, name: item.name})
        })
        const newData: AdminUpdateParentProfileInput = {
            id: editParentProfile.id,
            firstName: editParentProfile.firstName,
            kids: kidsArray,
            lastName: editParentProfile.lastName,
            middleName: editParentProfile.middleName,
            phoneNumber: editParentProfile.phoneNumber + '',
            telegramAccount: editParentProfile.telegramAccount,
        }
        updateParentProfile({
            variables: {
                input: newData
            }
        }).then((response: any) => {
            if (response.data) {
                const tabs = [ ...tabsParentProfile ];
                let kidsUpdateArray:  InputMaybe<KidProfile[]> = [];
                editParentProfile.kids.forEach((item) => {
                    kidsUpdateArray?.push(
                        {id: item.id, age: item.age, name: item.name})
                });
                const findId = tabs.findIndex((prod: ParentProfile) => prod.id === editParentProfile.id);
                if (findId) {
                    tabs[findId].firstName = editParentProfile.firstName;
                    tabs[findId].kids = kidsUpdateArray;
                    tabs[findId].lastName = editParentProfile.lastName;
                    tabs[findId].middleName = editParentProfile.middleName;
                    tabs[findId].phoneNumber = editParentProfile.phoneNumber + '';
                    tabs[findId].telegramAccount = editParentProfile.telegramAccount;
                }
                setTabsParentProfile(tabs);
                setIsOpenEditForm(false);
            }})
            .catch((error: any) => {
                console.log(error);
                setIsOpenEditForm(false);
            })
    }

    const handleDeleteParentProfile = (id: number) => {
        return deleteParentProfile({
            variables: {
                id: id
            }
        })
            .then((response: any) => {
                if (response.data) {
                    if (tabsParentProfile.length > 1) {
                        const tabs = [...tabsParentProfile];
                        const findId = tabs.findIndex((prod: ParentProfile) => prod.id === id);
                        tabs.splice(findId, 1);
                        setTabsParentProfile(tabs);
                    } else {
                        setTabsParentProfile(testParentProfileArray);
                    }
                }
            })
            .catch((error: any) => {
                console.error(error);
            });
    }

    useEffect(() => {

        if (state.dashboardName !== 'ParentProfile') {
            changeDashboardName('ParentProfile');
        }

        if (data !== undefined || !loading ) {
            parentProfilesData = data?.parentProfiles.items || testParentProfileArray;
            setTabsParentProfile(parentProfilesData);
        }

    }, [data]);

    return (
        <div className='main'>
            <h2>ParentProfiles</h2>
                 <Box style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            style={{ minHeight: '40px', minWidth: '25px', marginTop: '20px' }}
                            size='small'
                            variant='contained'
                            onClick={() => {
                                setIsOpenOneProfileWindow(false);
                                setIsOpenCreateWindow(false);
                                setIsOpenAllProfiles(true);
                            }}
                        >Show all profiles</Button>
                        <Divider
                            style={{
                                backgroundColor: 'grey',
                                height: '39px',
                                width: '1px',
                                marginTop: '20px',
                                marginLeft: '20px',
                            }}
                        />
                        <FormGroup row style={{ marginTop: '20px', marginLeft: '20px' }}>
                            <TextField
                                name='profile_id'
                                style={{ width: '100px' }}
                                id='outlined-password-input'
                                label='profile_id'
                                type='number'
                                size='small'
                                color='primary'
                                value={activeIdProfile}
                                onChange={handleIdProfileChange}
                            />
                            <Button
                                style={{ marginLeft: '10px' }}
                                variant='contained'
                                color='primary'
                                disabled = {isDisabled}
                                onClick={() => handleOpenOneProfile(activeIdProfile)}
                            >
                                Show Profile by Id
                            </Button>
                        </FormGroup>
                        <Divider
                            style={{
                                backgroundColor: 'grey',
                                height: '39px',
                                width: '1px',
                                marginTop: '20px',
                                marginLeft: '20px',
                            }}
                        />
                        <Button
                             style={{ minHeight: '40px', minWidth: '25px', marginTop: '20px', marginLeft: '20px' }}
                             size='small'
                             variant='contained'
                             onClick={() => {
                                 setIsOpenOneProfileWindow(false);
                                 setIsOpenAllProfiles(false);
                                 setIsOpenCreateWindow(true);
                             }}
                        >Create new profile</Button>
                    </Box>

            { !loading && isOpenAllProfiles
                ?
                tabsParentProfile  && tabsParentProfile.length >= 1
                    ? <Box>
                        {typeof (tabsParentProfile) !== 'undefined' && tabsParentProfile !== null && tabsParentProfile.map((item, index: number) =>
                            typeof (item) !== 'undefined' && item !== null &&
                            <Profile
                                key={index}
                                item={item}
                                handleOpenEditWindow={handleOpenEditForm}
                                remove={handleDeleteParentProfile}
                            />
                        )}
                    </Box>
                    : <div>No data</div>
                : <div></div>
            }
            {
                isOpenOneProfileWindow
                    ? <Profile
                        item={activeEditParentProfile}
                        handleOpenEditWindow={handleOpenEditForm}
                        remove={handleDeleteParentProfile}
                    />
                    : <Box></Box>
            }
            <br/>
            {
                isOpenCreateWindow
                    ?
                    < Box className={classes.createWindow}>
                        <ProfileForm
                            item={createProfile}
                            title='Создание нового профиля'
                            setCreateParentProfile={setCreateParentProfile}
                            handleCreateProfileParent={handleCreateProfileParent}
                        />
                    </Box>
                    : <Box></Box>
            }
            { isOpenEditForm
                ?
                < Box className={classes.editModal}>
                    <ProfileForm
                        item={editParentProfile}
                        title='Редактирование профиля'
                        setCreateParentProfile={setEditParentProfile}
                        handleCreateProfileParent={handleUpdateProfileParent}
                    />
                    <Box className={classes.closeButton}>
                        <IconButton
                            aria-label='delete'
                            size='large'
                            onClick={() => setIsOpenEditForm(false)}
                        >
                            <CancelRoundedIcon fontSize='inherit' />
                        </IconButton>
                    </Box>
                </Box>
                : <Box></Box>
            }
        </div>
    );
}