// import { makeStyles } from '@mui/styles';
import {
    Button,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    IconButton
} from '@mui/material';
import React, {useEffect} from 'react';
import {AuthContext} from '../../context/AuthContext';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useCalculateQuery, useGetDriverAndCarTypesQuery} from './__generated__/Calculator';

// const useStyles = makeStyles({
//     root: {
//         width: '100%',
//         padding: 10,
//     },
//     type: {
//         marginBottom: 15,
//         borderRadius: 9,
//         width: '200px',
//         height: 40,
//         backgroundColor: '#1976d2',
//         color: '#FFFFFF',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
//     }
// });
//
// interface CarType {
//     id: number;
//     title: string;
//     price: number;
// }
//
// interface DriveType {
//     id: number;
//     title: string;
// }

interface ChairsInput {
    boosters: number;
    chairs: number;
}

interface OrderDateTimeInput {
    date: string;
    time: string;
}

interface PointInput {
    id: number;
    address: string;
    latitude: number;
    longitude: number;
    sort: number;
}

const initialState: PointInput[] = [
    { id: 1, address: '',  latitude: 0, longitude: 0, sort: 1 },
    { id: 2, address: '',  latitude: 0, longitude: 0, sort: 2 },
];

export function Calculator() {
    const { state, changeDashboardName } = React.useContext(AuthContext);
    const [idDriveType, setIdDriveType] = React.useState<number>(1);
    const [idCarType, setIdCarType] = React.useState<number>(1);
    const [chairs, setChairs] = React.useState<ChairsInput>({ boosters: 1,  chairs: 1 });
    const [comment, setComment] = React.useState<string>('');
    const [dateTime, setDateTime] = React.useState<OrderDateTimeInput>({ date: '2022-03-03',  time: '12:00' });
    const [points, setPoints] = React.useState<PointInput[]>(initialState);
    const [range, setRange] = React.useState<number>(1);
    const [order, setOrder] = React.useState<any>(null);
    const { data } = useGetDriverAndCarTypesQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'no-cache'
    });
    const driversTypes = data?.driverTypes;
    const carTypes = data?.carTypes;

    console.log('Calculate');
    console.log(idCarType);
    console.log(chairs);
    console.log(comment);
    console.log(dateTime);
    console.log(points);
    console.log(range);

    const handleRangeChange = (event: any): void => {
        setRange(+event.target.value);
    };
    const handleCarTypeChange = (event: any): void => {
        setIdCarType(event.target.value as any);
    };
    const handleDriveTypeChange = (event: any): void => {
        setIdDriveType(event.target.value as any);
    };

    const handleChairsChange = (event: any): void => {
        setChairs({ ...chairs, [event.target.name]: +event.target.value});
    };

    const handleCommentChange = (event: any): void => {
        setComment(event.target.value);
    };

    const handlePointsChange = (id: any, event: any): void => {
        setPoints(
            points.map((item) => item.id === id ? { ...item, [event.target.name]: event.target.name === 'sort' ? +event.target.value : event.target.value} : item
            )
        );
    };
    const addPoint = (): void => {
        if (points.length >= 1) {
            const newPoints = [...points];
            const newId = Math.floor(Math.random() * 50);
            const findId = newPoints.findIndex((point) => point.id === newId);
            if (!findId) {
                addPoint();
            } else {
                newPoints.push({ id: newId, address: '',  latitude: 0, longitude: 0, sort: 1 });
                setPoints(newPoints);
            }
        } else {
            setPoints([{ id: 1, address: '',  latitude: 0, longitude: 0, sort: 1 }]);
        }
    };

    const deletePoint = (id: number): void => {
        const newPoints = [...points];
        if (newPoints.length > 1) {
            const findId = newPoints.findIndex((point) => point.id === id);
            newPoints.splice(findId, 1);
            setPoints(newPoints);
        } else {
            setPoints([]);
        }
    };

    const handleDataTimeChange = (event: any): void => {
        setDateTime({ ...dateTime, [event.target.name]: event.target.value});
    };

    const handleSubmit = (event: React.MouseEvent<Element, MouseEvent>) => {
        event.preventDefault();
        const input = {
            carType: idCarType,
            chairs: chairs,
            comment: comment,
            dateTime: dateTime,
            driverType: idDriveType,
            points: points,
            range: range
        }
        const { error } = useCalculateQuery({
            variables: {
                input: input
            }
        })
        order ? setOrder(order) : <Box>{error}</Box>
    }

    useEffect(() => {
        if (state.dashboardName !== 'Calculator') {
            changeDashboardName('Calculator');
        }
    }, []);

    return (
        <div style={{ padding: '1rem' }}>
            <h2>Calculator</h2>

            <br/>
            { /* choose DriveType */ }
            <Box>
                <FormControl sx={{ width: 300 }}>
                    <InputLabel id='demo-simple-select-label'>DriveType</InputLabel>
                    <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={idDriveType}
                        label='DriveType'
                        onChange={handleDriveTypeChange}
                    >
                        {
                            typeof (driversTypes) !== 'undefined' && driversTypes !== null &&
                            driversTypes.map((item, index) => {
                                return (
                                    <MenuItem key={index+1} value={item.id}>{item.title}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Box>
            <br/>
            { /* chairs */ }
            <Box>
                <TextField
                    id='standard-number'
                    label='boosters'
                    name='boosters'
                    type='number'
                    value={chairs.boosters ? chairs.boosters : ''}
                    variant='standard'
                    onChange={handleChairsChange}
                    sx={{ width: 150, marginRight: 3 }}
                />
                <TextField
                    id='standard-number'
                    label='chairs'
                    name='chairs'
                    type='number'
                    value={chairs.chairs ? chairs.chairs : ''}
                    variant='standard'
                    onChange={handleChairsChange}
                    sx={{ width: 150 }}
                />
            </Box>
            <br/>
            { /* comment */ }
                <TextField
                    id='standard-basic'
                    label='Comment'
                    variant='standard'
                    value={comment}
                    onChange={handleCommentChange}
                />
            <br/>
            <br/>
            { /* dateTime */ }
            <Box>
                <TextField
                    id='date'
                    label='Data'
                    type='date'
                    name='date'
                    value={dateTime.date}
                    sx={{ width: 220, marginRight: 3 }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleDataTimeChange}
                />
                <TextField
                    id='time'
                    label='Time'
                    type='time'
                    name='time'
                    value={dateTime.time}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    sx={{ width: 220, marginRight: 3 }}
                    onChange={handleDataTimeChange}
                />
            </Box>
            <br/>
            { /* driverTypes */ }
            <FormControl sx={{ width: 300, marginTop: 2 }}>
                <InputLabel id='demo-simple-select-label'>CarType</InputLabel>
                <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={idCarType}
                    label='DriveTypes'
                    onChange={handleCarTypeChange}
                >
                    {
                        typeof (carTypes) !== 'undefined' && carTypes !== null &&
                        carTypes.map((item, index) => {
                            return (
                                <MenuItem key={index+1} value={item.id}>{item.title} {item.price} руб.</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <br/>
            { /* points */ }

            <h3>Points</h3>
            <Button
                color='primary' 
                component='span'
                style={{marginBottom: 5}}
                onClick={addPoint}
            >
                <AddCircleOutlineIcon />
            </Button>
            {
                points.map(( point: PointInput, index: number ) => (
                    <Box key={index} sx={{ width: 500, display: 'flex', flexWrap: 'wrap' }}>
                        <Box style={{ width: '100%', borderTop: '2px solid #1976D2'}}></Box>
                        <TextField
                            id='standard-basic'
                            label='address'
                            name='address'
                            type='string'
                            value={point.address ? point.address : ''}
                            variant='standard'
                            onChange={(event: any) => handlePointsChange(point.id, event)}
                            sx={{ width: 220, marginRight: 3, marginTop: 1 }}
                        />
                        {/*<TextField*/}
                        {/*    id='standard-basic'*/}
                        {/*    label='latitude'*/}
                        {/*    name='latitude'*/}
                        {/*    type='string'*/}
                        {/*    value={point.latitude ? point.latitude : ''}*/}
                        {/*    variant='standard'*/}
                        {/*    onChange={(event: any) => handlePointsChange(point.id, event)}*/}
                        {/*    sx={{ width: 220, marginRight: 3, marginTop: 1 }}*/}
                        {/*/>*/}
                        {/*<TextField*/}
                        {/*    id='standard-basic'*/}
                        {/*    label='longitude'*/}
                        {/*    name='longitude'*/}
                        {/*    type='string'*/}
                        {/*    value={point.longitude}*/}
                        {/*    variant='standard'*/}
                        {/*    onChange={(event: any) => handlePointsChange(point.id, event)}*/}
                        {/*    sx={{ width: 220, marginRight: 3, marginTop: 1, marginBottom: 3 }}*/}
                        {/*/>*/}
                        <TextField
                            id='standard-number'
                            label='sort'
                            name='sort'
                            type='number'
                            value={point.sort ? point.sort : ''}
                            variant='standard'
                            onChange={(event: any) => handlePointsChange(point.id, event)}
                            sx={{ width: 220, marginRight: 3, marginTop: 1, marginBottom: 3 }}
                        />
                        <IconButton
                            aria-label='delete'
                            onClick={() => deletePoint(point.id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                ))
            }
            <br/>
            { /* range */ }
            <TextField
                id='standard-number'
                label='range'
                name='range'
                type='number'
                value={range ? range : ''}
                variant='standard'
                onChange={handleRangeChange}
                sx={{ width: 220, marginTop: 2, marginBottom: 3 }}
            />

            <br/>

            <Button onClick={(event) => handleSubmit(event)}>Отправить данные</Button>
        </div>
    );
}