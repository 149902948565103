import React from 'react';
import {ChairsInput, InputMaybe} from '../../../__generated__/types';
import {Booster} from './Booster';
import {Chair} from './Chair';

export function ChairsSelect(
    {
        chairs,
        updateChairsHandler
    }
        :
        {
            chairs:  InputMaybe<ChairsInput> | undefined,
            updateChairsHandler:  (chairs: ChairsInput) => void
        }
) {
    
    const toggleClickBoosterHandler = (count: any) => {
        updateChairsHandler({...chairs, ...{boosters: count}})
    };
    const toggleClickChairHandler = (count: any) => {
        updateChairsHandler({...chairs, ...{chairs: count}})
    };

    return (
        <div className='div-select-chair'>
            <div data-current='' data-easing='ease' data-duration-in='100' data-duration-out='100'
                 className='tabs-2 w-tabs'>
                <div className='set-servise-type w-tab-menu'>
                    <Booster
                        boosterCount={chairs?.boosters}
                        clickBoosterHandler={toggleClickBoosterHandler}
                    />
                    <Chair
                        chairCount={chairs?.chairs}
                        clickChairHandler={toggleClickChairHandler}
                    />
                </div>
            </div>
        </div>
    );
}
