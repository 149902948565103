import { Box, Button, Typography } from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid'
import {KidProfile, Point} from '../../../__generated__/types'
import React, {useState} from 'react';
import {useAssignDriverMutation, useCancelOrderMutation, useCompleteOrderMutation} from '../__generated__/Orders';
import {DriverProfileSelect} from '../../DriverProfileSelect/DriverProfileSelect';

export const OrderItem = ({
    id,
    closeWindow
}: {
    id: GridCellParams
    closeWindow: () => void
}) => {
    console.log(id)

    const [cancelOrder] = useCancelOrderMutation({
        variables: {
            id: id.row.id
        },
    });

    const callCancelOrder = () => {
        cancelOrder().then(() => closeWindow())
    };

    const [completeOrder] = useCompleteOrderMutation({
        variables: {
            id: id.row.id
        },
    });

    const callCompleteOrder = () => {
        completeOrder().then(() => closeWindow())
    };

    const [driverId, setDriverId] = useState<number>(null!)

    const [assignDriver] = useAssignDriverMutation({
        variables: {
            orderId: id.row.id,
            driverProfileId: driverId
        },
    });

    const callAssign = () => {
        assignDriver()
    }


    const getPoints = () => {
        return id.row.points.reduce(
            (prev: string, current: Point) =>
                prev ? prev + ' --> ' + current.address : current.address,
            ''
        )
    }

    return (
        <Box>
            <h4>Order info</h4>
            <Box>
                <Typography>Order ID : {id.row.id}</Typography>
                <Typography>Номер Заказа : {id.row.orderNumber}</Typography>
                <Typography>Статус заказа: {id.row.status}</Typography>
                <Typography>Статус оплаты: {id.row.paid ? 'Оплачен' : 'Не оплачен'}</Typography>
                {id.row.dateTime ? (
                    <>
                        <Typography>Дата : {id.row.dateTime.date}</Typography>
                        <Typography>Время : {id.row.dateTime.time}</Typography>
                    </>
                ) : null}
                <Typography>Стоимость : {id.row.price}</Typography>
                <Typography>Маршрут : {getPoints()}</Typography>
                <Typography>
                    Тип машины : {id.row.carTypes[0].title}
                </Typography>
                <Typography>
                    Категория водителя : {id.row.driverTypes[0].title}
                </Typography>
                <Typography>Boosters : {id.row.chairs.boosters}</Typography>
                <Typography>Chairs : {id.row.chairs.chairs}</Typography>
                <Typography>Comment : {id.row.comment}</Typography>
                <h5>Parent Profile</h5>
                <Typography>
                    First Name : {id.row.parentProfile.firstName}
                </Typography>
                <Typography>
                    Last Name : {id.row.parentProfile.lastName}
                </Typography>
                <Typography>
                    Middle Name :{' '}
                    {id.row.parentProfile.middleName
                        ? id.row.parentProfile.middleName
                        : 'null'}
                </Typography>
                <Typography>ID : {id.row.parentProfile.id}</Typography>
                <Typography>
                    Phone Number : {id.row.parentProfile.phoneNumber}
                </Typography>
                <Typography>
                    Telegram Account :{' '}
                    {id.row.parentProfile.telegramAccount
                        ? id.row.parentProfile.telegramAccount
                        : 'null'}
                </Typography>
                <h6>Kids</h6>

                {id.row.parentProfile.kids != null
                    ? id.row.parentProfile.kids.map(
                          (item: KidProfile, index: number) => {
                              return (
                                  <Box key={`BoxKey${index}`}>
                                      <Typography key={`kidName${index}`}>
                                          Name : {item.name}
                                      </Typography>
                                      <Typography key={`kidAge${index}`}>
                                          Age : {item.age}
                                      </Typography>
                                      <Typography key={`kidId${index}`}>
                                          ID : {item.id}
                                      </Typography>
                                  </Box>
                              )
                          }
                      )
                    : 'null'}
                <h6>Driver Profile</h6>
                {id.row.driverProfile ? (
                    <Box>
                        <Typography>
                            Active : {id.row.driverProfile.active}
                        </Typography>
                        <Typography>ID : {id.row.driverProfile.id}</Typography>
                        <Typography>
                            First Name : {id.row.driverProfile.firstName}
                        </Typography>
                        <Typography>
                            Last Name : {id.row.driverProfile.lastName}
                        </Typography>
                        <Typography>
                            Phone Number : {id.row.driverProfile.phoneNumber}
                        </Typography>
                        <Typography>
                            Telegram Account :{' '}
                            {id.row.driverProfile.telegramAccount
                                ? id.row.driverProfile.telegramAccount
                                : 'null'}
                        </Typography>
                        <Typography>
                            Driver Type :{' '}
                            {id.row.driverProfile.driverType.title}
                        </Typography>
                        <Typography>
                            Car Type : {id.row.driverProfile.carTypes[0].title}
                        </Typography>
                        <Typography>
                            Car Color : {id.row.driverProfile.carColor}
                        </Typography>
                    </Box>
                ) : (
                    'null'
                )}
            </Box>
            <Box>
                <DriverProfileSelect handleProfileSelect={setDriverId} />
                <Button
                    onClick={callAssign}
                    variant='contained'
                    color='primary'
                    style={{ width: 250, height: 50, marginRight: 5 }}
                >
                    Назначить водителя
                </Button>
            </Box>
            <Button
                onClick={closeWindow}
                variant='contained'
                color='primary'
                style={{ width: 250, height: 50, marginRight: 5 }}
            >
                Назад
            </Button>
            <Box>
                <Button
                    onClick={callCancelOrder}
                    variant='contained'
                    color='error'
                    style={{ width: 250, height: 50, marginRight: 5 }}
                >
                    Отменить заказ
                </Button>
                <Button
                    onClick={callCompleteOrder}
                    variant='contained'
                    color='success'
                    style={{ width: 250, height: 50, marginRight: 5 }}
                >
                    Заказ выполнен
                </Button>
            </Box>
        </Box>
    )
}
