import React, { FC } from 'react'
import { PlaceOrderInput, Point } from '../../../__generated__/types'
import { useNavigate } from 'react-router-dom'

interface propsType {
    placeOrder: PlaceOrderInput
    points: Point[]
    state: any
    price: number
    handlePlaceOrder: () => void
}

export const OrderButton: FC<propsType> = ({
    placeOrder,
    points,
    state,
    price,
    handlePlaceOrder
}) => {
    const navigate = useNavigate()
    return (
        <a
            href='#'
            className={
                placeOrder.carType != 0 &&
                placeOrder.driverType != 0 &&
                points.length > 1 &&
                placeOrder.parentProfileId != null
                    ? 'orderPanel__button'
                    : 'orderPanel__button button-off'
            }
            onClick={() => {
                state.userToken
                    ? handlePlaceOrder()
                    : navigate('/auth', { replace: true })
            }}
        >
            {state.userToken ? (
                price ? (
                    <div className='orderPanel__order'>
                        <span>Перейти к оплате</span>
                        <span>{price} ₽</span>
                    </div>
                ) : (
                    'Выберите опции заказа'
                )
            ) : (
                'Войти'
            )}
        </a>
    )
}
