import * as Types from '../../../__generated__/types.js'

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type ParentProfilesQueryVariables = Types.Exact<{
    input?: Types.InputMaybe<Types.PaginationInput>
}>

export type ParentProfilesQuery = {
    __typename: 'Query'
    parentProfiles: {
        __typename: 'ParentProfileList'
        items: Array<{
            __typename: 'ParentProfile'
            dateOfIssue?: string | null | undefined
            divisionCode?: string | null | undefined
            fullName?: string | null | undefined
            passportNumbers?: string | null | undefined
            canCallNanny: boolean
            whoIssued?: string | null | undefined
            firstName: string
            id: number
            lastName: string
            middleName?: string | null | undefined
            phoneNumber?: string | null | undefined
            telegramAccount?: string | null | undefined
            kids: Array<{
                __typename: 'KidProfile'
                fullName?: string | null | undefined
                birthCertificateNumber?: string | null | undefined
                birthday?: string | null | undefined
                dateOfIssue?: string | null | undefined
                insurancyPolicy?: string | null | undefined
                phoneNumber?: string | null | undefined
                snils?: string | null | undefined
                whoIssued?: string | null | undefined
                id: number
                name: string
                age: number
            }>
        }>
        pagination: {
            __typename: 'Pagination'
            limit?: number | null | undefined
            offset?: number | null | undefined
            totalCount?: number | null | undefined
        }
    }
}

export type OneParentProfileQueryVariables = Types.Exact<{
    id: Types.Scalars['Int']
}>

export type OneParentProfileQuery = {
    __typename: 'Query'
    parentProfile?:
        | {
              __typename: 'ParentProfile'
              dateOfIssue?: string | null | undefined
              divisionCode?: string | null | undefined
              fullName?: string | null | undefined
              canCallNanny: boolean
              passportNumbers?: string | null | undefined
              whoIssued?: string | null | undefined
              firstName: string
              id: number
              lastName: string
              middleName?: string | null | undefined
              phoneNumber?: string | null | undefined
              telegramAccount?: string | null | undefined
              kids: Array<{
                  __typename: 'KidProfile'
                  fullName?: string | null | undefined
                  birthCertificateNumber?: string | null | undefined
                  birthday?: string | null | undefined
                  dateOfIssue?: string | null | undefined
                  insurancyPolicy?: string | null | undefined
                  phoneNumber?: string | null | undefined
                  snils?: string | null | undefined
                  whoIssued?: string | null | undefined
                  id: number
                  name: string
                  age: number
              }>
          }
        | null
        | undefined
}

export type CreateParentProfileMutationVariables = Types.Exact<{
    input: Types.AdminCreateParentProfileInput
}>

export type CreateParentProfileMutation = {
    __typename: 'Mutation'
    createParentProfile: {
        __typename: 'ParentProfile'
        dateOfIssue?: string | null | undefined
        divisionCode?: string | null | undefined
        fullName?: string | null | undefined
        passportNumbers?: string | null | undefined
        canCallNanny: boolean
        whoIssued?: string | null | undefined
        firstName: string
        id: number
        lastName: string
        middleName?: string | null | undefined
        phoneNumber?: string | null | undefined
        telegramAccount?: string | null | undefined
        kids: Array<{
            __typename: 'KidProfile'
            fullName?: string | null | undefined
            birthCertificateNumber?: string | null | undefined
            birthday?: string | null | undefined
            dateOfIssue?: string | null | undefined
            insurancyPolicy?: string | null | undefined
            phoneNumber?: string | null | undefined
            snils?: string | null | undefined
            whoIssued?: string | null | undefined
            id: number
            name: string
            age: number
        }>
    }
}

export type UpdateParentProfileMutationVariables = Types.Exact<{
    input: Types.AdminUpdateParentProfileInput
}>

export type UpdateParentProfileMutation = {
    __typename: 'Mutation'
    updateParentProfile: {
        __typename: 'ParentProfile'
        dateOfIssue?: string | null | undefined
        divisionCode?: string | null | undefined
        fullName?: string | null | undefined
        passportNumbers?: string | null | undefined
        canCallNanny: boolean
        whoIssued?: string | null | undefined
        firstName: string
        id: number
        lastName: string
        middleName?: string | null | undefined
        phoneNumber?: string | null | undefined
        telegramAccount?: string | null | undefined
        kids: Array<{
            __typename: 'KidProfile'
            fullName?: string | null | undefined
            birthCertificateNumber?: string | null | undefined
            birthday?: string | null | undefined
            dateOfIssue?: string | null | undefined
            insurancyPolicy?: string | null | undefined
            phoneNumber?: string | null | undefined
            snils?: string | null | undefined
            whoIssued?: string | null | undefined
            id: number
            name: string
            age: number
        }>
    }
}

export type DeleteParentProfileMutationVariables = Types.Exact<{
    id: Types.Scalars['Int']
}>

export type DeleteParentProfileMutation = {
    __typename: 'Mutation'
    deleteParentProfile: boolean
}

export const ParentProfilesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ParentProfiles' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'PaginationInput' }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'parentProfiles' },
                        name: { kind: 'Name', value: 'parentProfiles' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'paginationInput'
                                },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'items' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dateOfIssue'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'divisionCode'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fullName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'passportNumbers'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'canCallNanny'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'whoIssued'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'firstName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'kids'
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'birthCertificateNumber'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'birthday'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'dateOfIssue'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'insurancyPolicy'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'phoneNumber'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'snils'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'whoIssued'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name'
                                                            }
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'age'
                                                            }
                                                        }
                                                    ]
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lastName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'middleName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'phoneNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'telegramAccount'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pagination' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'limit'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'offset'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'totalCount'
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useParentProfilesQuery__
 *
 * To run a query within a React component, call `useParentProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentProfilesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useParentProfilesQuery(
    baseOptions?: Apollo.QueryHookOptions<
        ParentProfilesQuery,
        ParentProfilesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<ParentProfilesQuery, ParentProfilesQueryVariables>(
        ParentProfilesDocument,
        options
    )
}
export function useParentProfilesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ParentProfilesQuery,
        ParentProfilesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        ParentProfilesQuery,
        ParentProfilesQueryVariables
    >(ParentProfilesDocument, options)
}
export type ParentProfilesQueryHookResult = ReturnType<
    typeof useParentProfilesQuery
>
export type ParentProfilesLazyQueryHookResult = ReturnType<
    typeof useParentProfilesLazyQuery
>
export type ParentProfilesQueryResult = Apollo.QueryResult<
    ParentProfilesQuery,
    ParentProfilesQueryVariables
>
export const OneParentProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'OneParentProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'parentProfile' },
                        name: { kind: 'Name', value: 'parentProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateOfIssue' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'divisionCode'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'canCallNanny'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'passportNumbers'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'whoIssued' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kids' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fullName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'birthCertificateNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'birthday'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dateOfIssue'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'insurancyPolicy'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'phoneNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'snils'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'whoIssued'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'age'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phoneNumber' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'telegramAccount'
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode

/**
 * __useOneParentProfileQuery__
 *
 * To run a query within a React component, call `useOneParentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneParentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneParentProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOneParentProfileQuery(
    baseOptions: Apollo.QueryHookOptions<
        OneParentProfileQuery,
        OneParentProfileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<
        OneParentProfileQuery,
        OneParentProfileQueryVariables
    >(OneParentProfileDocument, options)
}
export function useOneParentProfileLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        OneParentProfileQuery,
        OneParentProfileQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<
        OneParentProfileQuery,
        OneParentProfileQueryVariables
    >(OneParentProfileDocument, options)
}
export type OneParentProfileQueryHookResult = ReturnType<
    typeof useOneParentProfileQuery
>
export type OneParentProfileLazyQueryHookResult = ReturnType<
    typeof useOneParentProfileLazyQuery
>
export type OneParentProfileQueryResult = Apollo.QueryResult<
    OneParentProfileQuery,
    OneParentProfileQueryVariables
>
export const CreateParentProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'CreateParentProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'AdminCreateParentProfileInput'
                            }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'createParentProfile' },
                        name: { kind: 'Name', value: 'createParentProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateOfIssue' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'divisionCode'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'passportNumbers'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'canCallNanny'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'whoIssued' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kids' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fullName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'birthCertificateNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'birthday'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dateOfIssue'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'insurancyPolicy'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'phoneNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'snils'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'whoIssued'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'age'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phoneNumber' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'telegramAccount'
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type CreateParentProfileMutationFn = Apollo.MutationFunction<
    CreateParentProfileMutation,
    CreateParentProfileMutationVariables
>

/**
 * __useCreateParentProfileMutation__
 *
 * To run a mutation, you first call `useCreateParentProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParentProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParentProfileMutation, { data, loading, error }] = useCreateParentProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateParentProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateParentProfileMutation,
        CreateParentProfileMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        CreateParentProfileMutation,
        CreateParentProfileMutationVariables
    >(CreateParentProfileDocument, options)
}
export type CreateParentProfileMutationHookResult = ReturnType<
    typeof useCreateParentProfileMutation
>
export type CreateParentProfileMutationResult =
    Apollo.MutationResult<CreateParentProfileMutation>
export type CreateParentProfileMutationOptions = Apollo.BaseMutationOptions<
    CreateParentProfileMutation,
    CreateParentProfileMutationVariables
>
export const UpdateParentProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'UpdateParentProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'AdminUpdateParentProfileInput'
                            }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'updateParentProfile' },
                        name: { kind: 'Name', value: 'updateParentProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' }
                                }
                            }
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dateOfIssue' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'divisionCode'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'passportNumbers'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'canCallNanny'
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'whoIssued' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'kids' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fullName'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'birthCertificateNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'birthday'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dateOfIssue'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'insurancyPolicy'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'phoneNumber'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'snils'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'whoIssued'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name'
                                                }
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'age'
                                                }
                                            }
                                        ]
                                    }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'middleName' }
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'phoneNumber' }
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'telegramAccount'
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type UpdateParentProfileMutationFn = Apollo.MutationFunction<
    UpdateParentProfileMutation,
    UpdateParentProfileMutationVariables
>

/**
 * __useUpdateParentProfileMutation__
 *
 * To run a mutation, you first call `useUpdateParentProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParentProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParentProfileMutation, { data, loading, error }] = useUpdateParentProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateParentProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateParentProfileMutation,
        UpdateParentProfileMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        UpdateParentProfileMutation,
        UpdateParentProfileMutationVariables
    >(UpdateParentProfileDocument, options)
}
export type UpdateParentProfileMutationHookResult = ReturnType<
    typeof useUpdateParentProfileMutation
>
export type UpdateParentProfileMutationResult =
    Apollo.MutationResult<UpdateParentProfileMutation>
export type UpdateParentProfileMutationOptions = Apollo.BaseMutationOptions<
    UpdateParentProfileMutation,
    UpdateParentProfileMutationVariables
>
export const DeleteParentProfileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'DeleteParentProfile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' }
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' }
                        }
                    }
                }
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'deleteParentProfile' },
                        name: { kind: 'Name', value: 'deleteParentProfile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    ]
} as unknown as DocumentNode
export type DeleteParentProfileMutationFn = Apollo.MutationFunction<
    DeleteParentProfileMutation,
    DeleteParentProfileMutationVariables
>

/**
 * __useDeleteParentProfileMutation__
 *
 * To run a mutation, you first call `useDeleteParentProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParentProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParentProfileMutation, { data, loading, error }] = useDeleteParentProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteParentProfileMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteParentProfileMutation,
        DeleteParentProfileMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<
        DeleteParentProfileMutation,
        DeleteParentProfileMutationVariables
    >(DeleteParentProfileDocument, options)
}
export type DeleteParentProfileMutationHookResult = ReturnType<
    typeof useDeleteParentProfileMutation
>
export type DeleteParentProfileMutationResult =
    Apollo.MutationResult<DeleteParentProfileMutation>
export type DeleteParentProfileMutationOptions = Apollo.BaseMutationOptions<
    DeleteParentProfileMutation,
    DeleteParentProfileMutationVariables
>
